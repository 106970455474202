import { IHeaderWithCell, IHeader } from "../../../app/interface";
  
export const HeaderForTypeContract = (type: string, handleEdit?: (row: any) => void): IHeaderWithCell[] => {
    switch (type) {
        case 'client':
            return [
                { Header: 'Cliente', accessor: 'client' },
                { Header: 'Contratos consfirmados', accessor: 'confirmation' }
            ];
        case 'meat': // carne
            return [
                { Header: 'Data de Criação', accessor: 'payment' },
                { Header: 'Primeiro Venc.', accessor: 'firstExpiration' },
                { Header: 'Último Venc.', accessor: 'lastExpiration' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Ver Pacelas 
                            </button>
                        </div>
                    )
                }
            ];
        case 'installment' : // parcelas
            return [

                { Header: 'Parcela', accessor: 'number_parcel' },
                // { Header: 'Tipo', accessor: 'type_parcel' },
                { Header: 'Forma', accessor: 'form_payment' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Situação', accessor: 'status' },
                { Header: 'Ações',  
                    accessor: (row: any) => (
                        <div>
                            {handleEdit && typeof handleEdit === 'function' && (
                                <button
                                    className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                    onClick={() => handleEdit(row)}
                                >
                                    Editar
                                </button>
                                )}
                            
                        </div>
                    )
                }
            ];
        case 'contract' : // contratos
            return [                

                { Header: 'Contrato', accessor: 'name' },
                { Header: 'Valor', accessor: 'value' },
                // { Header: 'Situação', accessor: 'status' },
                { Header: 'Próximo pagamento', accessor: 'next_payment' },
                { Header: 'Fim de contrato', accessor: 'contract_expiration' },
                { Header: 'Ações',  
                    accessor: (row: any) => (
                        <div>
                            {handleEdit && typeof handleEdit === 'function' && (
                                <button
                                    className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                    onClick={() => handleEdit(row)}
                                >
                                    Editar
                                </button>
                                )}
                            
                        </div>
                    )
                }
            ];
        case 'unit': // unidade
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'type' },
                { Header: 'Contrato Atual', 
                    accessor: (row: any) => {
                        // console.log('botoes unidades ', row)
                        return (
                            <div>
                                {!!row.id_contract ? (
                                    <a  
                                        type="button"
                                        className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                        href={`/contract/details/${row.id_contract}/overview`}    
                                        >
                                            Detalhe do Contrato
                                    </a>
                                ): (
                                    <span>Sem contrato</span>
                                )}                                
                                
                            </div>
                        )
                    }
                },
                { Header: 'Ação', 
                    accessor: (row: any) => {
                        return (
                            <div>
                                
                                {handleEdit && typeof handleEdit === 'function' && (
                                    <button
                                        className="btn btn-sm ms-5 fw-bold bg-body btn-color-gray-700 btn-active-color-primary"
                                        onClick={() => handleEdit(row)}
                                    >
                                        Editar
                                    </button>
                                )}

                                
                                
                            </div>
                        )
                    }
                }
            ];
        default:
            return [
                { Header: 'Cliente', accessor: 'nameParticipant' },
                { Header: 'Contratos consfirmados', accessor: 'confirmation' }
            ];
    }
}; 

export default HeaderForTypeContract