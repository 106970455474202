import { FC, MouseEvent, useRef } from "react";
import jsPDF  from "jspdf";
import autoTable from 'jspdf-autotable';
import { ButtonPDF } from "../buttons/ButtonPdf";

type ExportType<T> = {
    [key in keyof T]: string;
}

interface Props<T>  {
    exportData: T[];
    exportFileName: string;
    exportTableName?: string;
    exportHeader?: { header: string; dataKey: string }[];
    exportFooter?: { label: string; key: string }[];
}

export const ExportTablePdf = <T extends {}>({
    exportData,
    exportFileName,
    exportTableName,
    exportHeader,
    exportFooter,
}: Props<T>) => {
    const tableRef = useRef<HTMLTableElement>(null);

    const handleExportPDF = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const doc = new jsPDF();

    if(exportTableName)
    {
        autoTable(doc, { html: `#${exportTableName}` })
    }else{
        autoTable(doc, {
            body: exportData,
            columns: exportHeader
        });
    }

    doc.save(`${exportFileName}.pdf`);
    
  };

    return (
        <>
            <button
                className="btn btn-sm btn-flex bg-light btn-color-primary-700 btn-active-color-primary fw-bold me-2" 
                onClick={handleExportPDF}>
                PDF
            </button>
        </>
    );
};
