import React, { useEffect } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import Select from "react-select";
import { FormProps, FormValues } from "../../../../../app/interface/I_Parcel";


export const FormEditParcel = ({ 
    closeModal,
    handlerSaveForm,
    formValues,
    handleChange 
}: FormProps) => {
    
    interface Option {
        value: string;
        label: string;
    }

    const optionsTypePayment: Option[] = [
        { value: "Transferencia", label: "Transferência" },
        { value: "Boleto", label: "Boleto" },
        { value: "Pix", label: "Pix" }
    ];

    const optionsStatus: Option[] = [
        { value: "Pendente", label: "Pendente" },
        { value: "Pago", label: "Pago" },
        { value: "Recorrente", label: "Recorrente" }
    ];

    useEffect(() => {
        const formattedValue = formatarValor(formValues.value);
        handleChange("value", formattedValue);
    }, []);

    
    const handleInputChange = (
        value: string,
        fieldName: keyof FormValues
      ) => {

        // const value  = fieldName === 'value' ?  : e.target.value;
        // const value  = e.target.value;
        console.log('new valor format ', value)
        handleChange(fieldName, value);
    };

    const formatarValor = (value: any) => {

        var newValue = value;
        

        newValue = newValue + '';
        newValue = parseInt(newValue.replace(/[\D]+/g,''));
        if (isNaN(newValue))
        {
            return `R$ 0,00`;
        }
        newValue = newValue + '';
        newValue = newValue.replace(/([0-9]{2})$/g, ",$1");


        if (newValue.length > 6)
        {
            newValue = newValue.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        newValue = `R$ ${newValue}`;

        return newValue;
    
    };
    
    const handleSelectChange = <K extends keyof FormValues>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        handleChange(fieldName, value);
    };    

    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                            <div className="container-fluid">
                                <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                            <div
                                                className="pe-12 me-n12"
                                            >
                                                <div className="row gy-5">
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Forma de pargamento</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Forma de pargamento"
                                                                value={formValues.form_payment ? { value: formValues.form_payment, label: formValues.form_payment } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "form_payment")}
                                                                options={optionsTypePayment}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Vencimento</label>
                                                        <div className='d-flex'>
                                                            <input 
                                                                type="date" 
                                                                className="form-control" 
                                                                name="due_date"
                                                                value={formValues.due_date}
                                                                onChange={(e) => {
                                                                        handleInputChange(e.target.value, "due_date")
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Valor</label>
                                                        <div className='d-flex'>
                                                            <input 
                                                                className="form-control" 
                                                                name="value"
                                                                value={formValues.value}
                                                                onChange={(e) => {
                                                                    
                                                                        const newValue = formatarValor(e.target.value).toString()
                                                                        handleInputChange(newValue, "value")
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Situação</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione uma situação"
                                                                value={formValues.status ? { value: formValues.status, label: formValues.status } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status")}
                                                                options={optionsStatus}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer border-0">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="button" className="btn btn-success me-4" onClick={handlerSaveForm}>
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}