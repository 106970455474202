import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { getUnitsByIdProduct } from '../../../../app/modules/unit/requestUnits';
import { useAuth } from '../../../../app/modules/auth';
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import { formatarValor } from '../../../helpers/functions/numbers';

const FormFilterPayment: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const [resetDate, setResetDate] = useState<boolean>(false);

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_product: string;
        type_payment: string;
        assessment: string; // avaliação
        boleto: string;
    }
        
    const optionsTypePayments: Option[] = [
        { value: "", label: "Todos" },
        { value: "LQ", label: "Liquidados" },
        { value: "VC", label: "Vencidos" },
        { value: "RC", label: "A Receber" }
    ];
        
    const optionsAction: Option[] = [
        { value: "", label: "Todos" },
        { value: ">=", label: "Maior ou igual ao" },
        { value: "<=", label: "Menor ou igual ao" },
        { value: "==", label: "Igual ao" },
    ];

    const optionsBoleto: Option[] = [
        { value: "", label: "Todos" },
        { value: "sim", label: "Sim" },
        { value: "nao", label: "Não" },
    ];

    const [formData, setFormData] = useState({
        id_product: '0', 
        type_payment: '',
        contract: '',
        client: '',
        
        start_date: '',
        end_date: '',
        value: '',
        date_payment: '',
        assessment: '',
        boleto: '',
        or_number: '',
    });
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    // data de vencimento
    function handleDateRangeDueDatePayment(event: Event, picker: any) {
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData(prevState => ({
            ...prevState,            
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD')
            
        }));
    }

    // data de pagamento
    function handleDateRangePayment(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData(prevState => ({
            ...prevState,                
            ['period_2']: {
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD')
            }
        }));
    }   
    
    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        setFormData(prevState => ({
            ...prevState,                
            [fieldName]: value.toString(),
        }));
    };

    const onSelectTagify = (e:any, fieldName:string) => {
        
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: e.target.innerText,
        }));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));        
    }

    const handleInputChange = useCallback((e:any) => {

        const { name, value } = e.target;

        let newValue = name === 'value' ? formatarValor(value).toString() : value;
        setFormData(prevState => ({
            ...prevState,            
            [name]: newValue
        }));
    }, []);

    const resetFilterForm = () => {
        
        setResetDate(true);

        setFormData({
            id_product: '0', 
            type_payment: '',
            contract: '',
            client: '',
            start_date: '',
            end_date: '',
            value: '',
            date_payment: '',
            assessment: '',
            boleto: '',
            or_number: '',
        });
        

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));
    }

    const handleResetComplete = () => {
        // Reset concluído, definir shouldResetDates como false
        setResetDate(false);
      };


    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    
                    <div className='mb-10 row'>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Situação</label>
                            <div className=''>
                                <Select
                                    name="type_pyment"
                                    className="w-auto"
                                    placeholder="Selecione um tipo"
                                    value={{value: formData.type_payment, label: optionsTypePayments.find(option => option.value === formData.type_payment)?.label}}
                                    options={optionsTypePayments}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_payment")}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-7"></div>

                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Contrato</label>
                            <div className=''>
                                <TagifyCustom
                                    onSelectTagify={onSelectTagify}
                                    selectedId=""
                                    selectedName=""
                                    endPoint='contract'
                                />
                            </div>
                        </div>                        

                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Cliente</label>
                            <div className=''>
                                <TagifyCustom
                                    onSelectTagify={onSelectTagify}
                                    selectedId=""
                                    selectedName=""
                                    endPoint='client'
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-7"></div>

                        <div className='col-md-6'>
                            <label className='form-label fw-bold'>Vencimento</label>
                            <DateRangePickerComponent 
                                onDateRangeSelection={handleDateRangeDueDatePayment}
                                className=''
                                resetDate={resetDate}
                                onResetComplete={handleResetComplete}
                            />
                            {/* Rest of your component code */}
                        </div>

                        {/* <div className='col-md-6'>
                            <label className='form-label fw-bold'>Data Pagamento</label>
                            <DateRangePickerComponent 
                                onDateRangeSelection={handleDateRangePayment}
                                className=''
                                resetDate={resetDate}
                                onResetComplete={handleResetComplete}
                            />
                            
                        </div> */}

                        
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Ação</label>
                            <div className=''>
                                <Select
                                    name="assessment"
                                    className="w-auto"
                                    placeholder="Selecione"
                                    value={{ value: formData.assessment, label: optionsAction.find(option => option.value === formData.assessment)?.label }}
                                    options={optionsAction}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "assessment")}
                                />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-7"></div>

                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Valor</label>
                            <div className=''>
                                <input
                                    name="value"
                                    className="form-control"
                                    placeholder="R$ 0,00"
                                    value={formData.value !== "R$ 0" && formData.value !== "" && formData.value !== "R$ 0,0" ? formData.value : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Com Boleto?</label>
                            <div className=''>
                                <Select
                                    name="boleto"
                                    className="w-auto"
                                    placeholder="Selecione"
                                    value={{ value: formData.boleto, label: optionsBoleto.find(option => option.value === formData.boleto)?.label }}
                                    options={optionsBoleto}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "boleto")}
                                />
                            </div>
                        </div>

                        {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Nosso Número</label>
                            <div className=''>
                                <input
                                    name="or_number"
                                    className="form-control"
                                    placeholder=""
                                    value={formData.or_number !== "" ? formData.or_number : ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div> */}

                    </div>                
                    
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterPayment;
