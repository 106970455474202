import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IResponse } from "../../../interface/I_General";
import { OptionSelect } from "../../../interface/I_Product";
import { getToken } from "../auth/getToken";

const API_URL = process.env.REACT_APP_API_URL;

export const listProduct = createApi({
    reducerPath: 'lisProduct',
    baseQuery:fetchBaseQuery({
        baseUrl:API_URL,
        prepareHeaders:(headers) => {
            const  dataLocalStorage = getToken();
            
            if (dataLocalStorage?.token) {
                headers.set('Authorization', `Bearer ${dataLocalStorage?.token}`);
                console.log('localstorage ', dataLocalStorage?.token)
            }
            return headers;
        },
    }),
    endpoints:(builder) => ({
        fetchListProduct: builder.query<IResponse, null>({
            query:(params) => ({
                url: `module/productAll`,
                method:'post'
            }),
        }),

    }),
});
export const { useFetchListProductQuery } = listProduct