import React, { useContext, useEffect, useState } from 'react';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";


import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';  
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { IFilterParcel } from '../../../../app/interface/I_Parcel';

const FormFilterLisParcel: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);
    const [formData, setFormdata] = useState({
        id_product: '',
        client: '',
        due_date: '',
        status: '',
        start_date : '',
        end_date: '',
        type_parcel: '',
        type_payment: ''
    });

    // const [selectedProduto, setSelectedProduto] = useState('');
    const [nameClient, setNameClient] = useState('');

    
    console.log('produstos query ', products)
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    interface Option {
        value: string;
        label: string;
    }

    const optionsTypePayment: Option[] = [
        { value: "Transferencia", label: "Transferência" },
        { value: "Boleto", label: "Boleto" },
        { value: "Pix", label: "Pix" }
    ];

    const optionsStatus: Option[] = [
        { value: "pendente", label: "Pendente" },
        { value: "pago", label: "Pago" },
        { value: "Recorrente", label: "Recorrente" }
    ];

    // tipo parcela
    const optionsTypeParcel: Option[] = [
        { value: "AV", label: "Á vista" },
        { value: "CO", label: "Comissão Corretor" },
        { value: "COR", label: "Comissão Corretor (Repasse)" },
        { value: "CH", label: "Comissão Interna" },
        { value: "CHR", label: "Comissão Interna (Repasse)" },
        { value: "DS", label: "Desconto" },
        { value: "SI", label: "Entrada" },
        { value: "FG", label: "FGTS" },
        { value: "FB", label: "Financiamento Bancário" },
        { value: "IT", label: "Intermediaria"},
        { value: "PC", label: "Parcela de chaves"},
        { value: "PM", label: "Parcela Mensal" },
        { value: "SB", label: "Subsídio" },
        // { value: "TR", label: "Trimestral" },
        // { value: "SM", label: "Semestral" },
        // { value: "AN", label: "Anual" },
    ];

    const [dueDate, setDueDate] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
    });

    function handleDateRangeDueDate(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        // setDueDate({ start_date: start_date, end_date: end_date });

        setFormdata(prevState => ({
            ...prevState,
            ['start_date'] : start_date,
            ['end_date'] : end_date,
        }));
    }

   

    const onSelectTagify = (e:any) => {
        setFormdata(prevState => ({
            ...prevState,
            ['client'] : e.target.innerText,
        }));
    }

    const handleSelectChange = <K extends keyof IFilterParcel>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";

        setFormdata(prevState => ({
            ...prevState,
            [fieldName] : value,
        }));
        
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        // const formDataReact = new FormData();

        console.log('submit ', formData)

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));
        
    }

    const resetFilterForm = () => {

        const { 
            id_product,
            client,
            due_date,
            status,
            start_date ,
            end_date,
            type_parcel,
            type_payment,
        } = {
            id_product: '',
            client: '',
            due_date: '',
            status: '',
            start_date : '',
            end_date: '',
            type_parcel: '',
            type_payment: ''
        };

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ 
            id_product,
            client,
            due_date,
            status,
            start_date ,
            end_date,
            type_parcel,
            type_payment, 
        }));
    }

    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Cliente</label>
                            <div className=''>
                            <TagifyCustom
                                onSelectTagify={onSelectTagify}
                                selectedId=""
                                selectedName=""
                                endPoint="client"
                            />
                            </div>
                        </div>

                        <div className="mb-4"></div>

                        <div className='col-md-6'>
                            <label className='form-label fw-bold'>Vencimento</label>
                            <DateRangePickerComponent 
                                onDateRangeSelection={handleDateRangeDueDate}
                                className="w-auto"
                            />
                            
                        </div> 
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Forma de Pagamento</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione"
                                    value={formData.type_payment !== '0' && formData.type_payment !== '' ? { value: formData.type_payment, label: optionsTypePayment.find(option => option.value === formData.type_payment)?.label } : null}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_payment")}
                                    options={optionsTypePayment}
                                />
                            </div>
                        </div>  
                        
                        <div className="mb-4"></div>

                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Status</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione"
                                    value={formData.status !== '0' && formData.status !== '' ? { value: formData.status, label: optionsStatus.find(option => option.value === formData.status)?.label } : null}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status")}
                                    options={optionsStatus}
                                />
                            </div>
                        </div> 
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Tipo de parcela</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione"
                                    value={formData.type_parcel !== '0' && formData.type_parcel !== '' ? { value: formData.type_parcel, label: optionsTypeParcel.find(option => option.value === formData.type_parcel)?.label } : null}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_parcel")}
                                    options={optionsTypeParcel}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterLisParcel;
