import { IHeaderWithCell } from "../../../interface";
  
/**
 * 
 * @param type
 *  idContract:number | string;
    idClient: number;
    contract:string;
    numberInstallment:string;
    nameCliente:string;
    expiration:string;
    valueInstallment: string;
    descriptionValueInstallment?: string;
    datePayment:string;
    valuePayment:string;
    situationInstallment:string;
    email:string
 * @returns 
 */
export const HeaderTablePayment = (type: string): IHeaderWithCell[] => {
    switch (type) {
        case 'listPayment': // lista de pagamentos
            return [
                { Header: 'Contrato', accessor: 'contract' },
                { Header: 'Cliente', accessor: 'name_client' },
                { Header: 'Valor', accessor: 'value' },
                { Header: 'Valor Renegociado', accessor: 'renegotiatedvalue' },
                { Header: 'Vencimento', accessor: 'due_date' },
                { Header: 'Forma de pagamento', accessor: 'type_parcel' },
                { Header: 'Situação', accessor: 'status' },
                { Header: 'Email', accessor: 'email_ready' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Ver Boleto 
                            </button>
                        </div>
                    )
                },
            ];

            case 'installment': // lista de pagamentos
            return [
                { Header: 'Situação', accessor: 'situation' },
                { Header: 'Vencimento', accessor: 'expiration' },
                { Header: 'Valor', accessor: 'valueInstallment' },
                { Header: 'Criado Via', accessor: 'situationInstallment' },
                { Header: 'Tipo Cobrança', accessor: 'typeInstallment' }
            ];
        default:
            return [
                { Header: 'Contrato', accessor: 'contract' },
                { Header: 'Cliente', accessor: 'nameclient' },
                { Header: 'Vencimento', accessor: 'expiration' },
                { Header: 'Valor', accessor: 'valueInstallment' },
                { Header: 'Data Pagamento', accessor: 'datePayment' },
                { Header: 'Pago', accessor: 'valuePayment' },
                { Header: 'Situação', accessor: 'situationInstallment' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Ver Boleto 
                            </button>
                        </div>
                    )
                },
            ];
    }
}; 

export default HeaderTablePayment