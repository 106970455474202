import axios from "axios";
import { AuthType, IResponse } from "../../interface/I_General";

const API_URL = process.env.REACT_APP_API_URL;

// retorna daods do cliente
export async function getClientByName(name: string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getClientByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}