import * as yup from 'yup';

export const SchemaProduct = yup.object().shape({

    name: yup.string().nullable().required('O campo nome do produto é obrigatório'),
    name_owner: yup.string().nullable().required('O campo nome do proprietário é obrigatório'),
    phone: yup.string().matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Insira um telefone válido').nullable().required('O campo Telefone é obrigatório'),
    cep: yup.string().matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido').nullable().required('O campo CEP é obrigatório'),
    cpf_cnpj: yup.string().matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Insira um CPF válido').required('O campo CPF é obrigatório'),  
    rg: yup.string().nullable().required('O campo Rg é obrigatório'),
    country: yup.string().nullable().required('O campo País é obrigatório'),
    number: yup.string().nullable().required('O campo Número é obrigatório'),
    address: yup.string().nullable().required('O campo Endereço é obrigatório'),
    neighborhood: yup.string().nullable().required('O campo Bairro é obrigatório'),
    city: yup.string().nullable().required('O campo Cidade é obrigatório'),
    state: yup.string().nullable().required('O campo Estado é obrigatório')
});

export const schemaInsertProduc = yup.object().shape({    
    name: yup.string().required('O campo nome do produto é obrigatório'),
    product_plan: yup.string().required('O campo Tipo do produto é obrigatório'),
});
