import { KTSVG } from "../../helpers";
import { useEffect, useState } from "react";
import { detailsContract, getMeatContract, getParticipant } from "../../../app/modules/contract/_request";
import { IDadosGerais, ICarne } from "../../../app/interface/I_Contract";
import { IParticipant } from "../../../app/interface/I_Client";
import TableSimple from "../../layout/components/tables/TableSimple";
import getHeaderForTypeContract from "./HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table"; 

export const DetailsContract = ({ id }: { id: number | undefined }) => {
    const [contractDataDetails, setContractDataDetails] = useState<IDadosGerais | null>(null);
    const [contractDataMeat, setContractDataMeat] = useState<ICarne | null>(null);
    const [contractDataParticipant, setContractDataParticipant] = useState<IParticipant | null>(null);
        console.log('id teste', id)
    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsClient: TableOptions = {
        header: getHeaderForTypeContract('client'),
        iInterface: 'participante',
        endPoint: `getParticipant/${id}/`,
        methodEndPoint: 'post',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'min-w-150px',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        isHidePaginate:true,
        classTbodyTdDiv:'fw-normal text-gray-600'
    };

    
    const optionsCarne: TableOptions = {
        header: getHeaderForTypeContract('meat'),
        iInterface: 'carne',
        endPoint: 'getMeatContract',
        methodEndPoint: 'get',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'min-w-150px',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        classTbodyTdDiv:'fw-normal text-gray-600'
    };

    useEffect(() => {
        async function fetchData() {

            // detalhes do contrato
            try {
                const responseDetails = await detailsContract(id || -1);
                setContractDataDetails(responseDetails.data);
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseDetails:", error);
            }            
            
            
            /**
            // lista de carne
            try {
                const responseMeat = await getMeatContract(id || -1);
                setContractDataMeat(responseMeat.data);
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseMeat:", error);
            }

            // lista de participante
            try {
                const responseParticipant = await getParticipant(id || -1);
                setContractDataParticipant(responseParticipant.data);
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseParticipant:", error);
            }

            */
        }
        fetchData();
      }, [id]);
      

      let situation = '';
    if(!!contractDataDetails?.cancellation_date)
    {
        situation = 'Com distrato';
    }else if(!!contractDataDetails?.discharge_date)
    {
        situation = `Quitado em ${contractDataDetails?.discharge_date}`
    }else{
        situation = `Em andamento`;
    }

    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <div className="card-header">
                
                <div className="card-title">
                    <h2 className="fw-bold">Detalhe do Contrato</h2>
                </div>
            </div>

            <div className="card-body pt-3">
                <div className="mb-10">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Dados gerais:</h5>

                    <div className="d-flex flex-wrap py-5">
                        <div className="flex-equal me-5">
                            <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-400 min-w-175px w-175px">Empreendimento:</td>
                                        <td className="text-gray-800 min-w-200px">
                                            {contractDataDetails?.name_product || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Unidade:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.name_unit || '...'}
                                        </td>
                                    </tr>

                                    {/* <tr>
                                        <td className="text-gray-400">Situação:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.situation || '...'}
                                        </td>
                                    </tr> */}

                                    <tr>
                                        <td className="text-gray-400">Contrato:</td>
                                        <td className="text-gray-800">
                                            {situation}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="text-gray-400">Previsão de termino:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.contract_expiration !== null ? `Em ${contractDataDetails?.contract_expiration}` : '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Valor total:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.value || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Índice de correção monetária:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.correctionIndex || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Multa por atraso:</td>
                                        <td className="text-gray-800">
                                            {/* multa por atraso */}
                                            {contractDataDetails?.lateFee || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Juros por atraso:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.overdueInterest}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-400">Identificador externo:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.id_proposal_external}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Participantes */}
                <div className="mb-0">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Participantes:</h5>
                    <TableSimple options={optionsClient} />
                </div>
                
                {/* Carne */}
                <div className="mb-0 mt-5">
                    <div className="d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">
                        <div className="fw-bold">
                            Carnê
                        </div>
                        
                        <div className="badge badge-light-info d-inline">Adicionar carnê</div>
                        
                    </div>
                    <TableSimple options={optionsCarne} />
                </div>

            </div>
        </div>

    );
}