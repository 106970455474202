import { KTSVG } from "../../../../_metronic/helpers"; 
import { useEffect, useState } from "react";
import { getListPayments } from "../../../modules/payment/payments"; 

import HeaderTablePayment from "./HeaderTablePayment";
import { TableOptions } from "../../../interface/I_Table";  
import TableAdvanced from "../../../../_metronic/layout/components/tables/TableAdvanced";
import { IPayments } from "../../../interface/I_Payments"; 

export const ListPayments = () => {

    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsInstallment: TableOptions = {
        header: HeaderTablePayment('listPayment'),
        iInterface: 'listPayment',
        endPoint: 'getListPayments',
        methodEndPoint: 'post',
        title:'Lista de Parcelas',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: '',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        classTbodyTdDiv:'fw-normal text-gray-600'
    };
    
    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <TableAdvanced options={optionsInstallment} />
        </div>

    );
}