import axios from 'axios'
import { IResponse, AuthType} from '../../interface/I_General';
import { FormInsertProduct, formProduct } from '../../interface/I_Product';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllProduct = async (auth: AuthType)  => {

    try{
        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/productAll`,
                            {},
                            { headers }
                        );

        return response;
    }catch(error){

    }
}

// deleta uma ou mais produtos
export async function deleteProductsAPI(ids: string[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/deleteProduct`,
                            { ids },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// recupera um produto
export async function getDataProduct(id: string | number, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getProductById`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// recupera um produto
export async function saveProduct(data: formProduct | FormInsertProduct, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/saveProduct`,
                            { data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}


// recupera as imagensdo produto
export async function getImagensProduct(id: string | number , auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getImagensProduct`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}