/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
// import { StyledDivWidgetVendas } from '../../assets/styling/dashboard/StylingWidgetVendas'
import { KTSVG, toAbsoluteUrl } from '../../helpers' 
import {FC, useEffect, useRef, useState} from 'react'
import { Link, Routes } from 'react-router-dom'
import { RootStateFilter } from '../../../app/interface/I_Filter'
import { useSelector } from 'react-redux';
import { useFetchReportDasboardQuery } from '../../../app/redux/api/dashboard/reportDashboard'

type Props = {
  className?: string
  styleLinearBackgoundVendas?:string
  styleLinearColorVendas?:string
  description?: string
  icon?: boolean
  labelColor?: string
  textColor?: string
}
  
  const CardBudgetVendas: FC<Props> = ({
    className,
    styleLinearBackgoundVendas = `linear-gradient(112.14deg, #655ec9 0%, #322c91 100%)`,
    styleLinearColorVendas = `rgba(255, 255, 255, 0.15)`,
    description,
    icon,
    labelColor,
    textColor
  }) => {

    const divRef = useRef<HTMLDivElement | null>(null)

	const filterForm = useSelector((state:RootStateFilter) => state.filter);
	const { data, error, isLoading } = useFetchReportDasboardQuery(filterForm);
	const newData = data?.data !== undefined !== null ? data?.data : [{id:0, titulo:'', name:'', state:'', total:'', icon:''}]

	// const items: Array<{
	// 	id:number
	// 	titulo: string
	// 	name?: string
	// 	state?: string
	// 	total?:string
	// 	icon:string
	// }> = newData;
	// [
		// {id:1, name:'tasks', titulo: 'Tarefas em aberto', total: '0', state: 'warning', icon:'/media/icons/duotune/general/gen049.svg'},
		// {id:2, titulo: 'Contratos ativos', total: '0', state: 'warning', icon:'/media/icons/duotune/general/gen026.svg'},
		// {id:3, titulo: 'A receber', total:'R$ 0,00', icon: '/media/icons/duotune/general/gen056.svg'},
		// {id:4, titulo: 'Recebido', total: 'R$ 0,00', state: 'primary', icon:'/media/icons/duotune/ecommerce/ecm001.svg'},
		// {id:5, titulo: 'Vendidos', total: 'R$ 0,00', icon: '/media/icons/duotune/ecommerce/ecm001.svg'},
		
	// ]

    useEffect(() => {
        refreshDiv();
		
	},[data])
    
	const refreshDiv = () => {
		if (!divRef.current) {
			
			return
		}

	}

	console.log('redux filterForm ', filterForm)
	console.log('redux data ', data, isLoading)

    return (
     
        <div className="col-xl-12 mb-xl-10">
			<div className="card h-lg-100"  ref={divRef} style={{backgroundImage:styleLinearBackgoundVendas}}>
				
				<div className="card-body">
					<div className="row align-items-center">
						<div className="col-sm-9 pe-0 mb-5 mb-sm-0">
							<div className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
								<div className="mb-7">
									<div className="mb-6">
										<h3 className="fs-2x fw-semibold text-white">Posição de cobrança</h3>
										<span className="fw-semibold text-white opacity-75">Relatórios de todos seus clientes</span>
									</div>

									<div className="d-flex align-items-center flex-wrap d-grid gap-2">
										{
											data && Array.isArray(data) ? (
												data.map((item) => {
													if(item?.name == 'tasks'){
													return (													
														
															<div key={item.id} className="d-flex align-items-center me-3 me-xl-7">
																<div className="symbol symbol-30px symbol-circle me-3">
																	<span className="symbol-label" ref={divRef} style={{background:styleLinearColorVendas}}>
																		
																		<KTSVG
																			path={item.icon}
																			className="svg-icon svg-icon-1x svg-icon-white"
																		/>
																	</span>
																</div>
	
																<div className="m-0">
																	<span className="text-white text-opacity-75 fs-8">{item.titulo}</span>
																	<span className="fw-bold text-white fs-7 d-block">{item.total}</span>
																	<Link className="fw-bold text-white fs-9 d-block" to="../tasks">
																		Ir para tarefas &nbsp;
																		<KTSVG className='svg-icon-white' path={'/media/icons/duotune/arrows/arr036.svg'}/>
																	</Link>
																</div>
															</div>
														
													);
													
												
													}else{
											
														return (
															<div key={item.id} className="d-flex align-items-center me-3 me-xl-7">
																<div className="symbol symbol-30px symbol-circle me-3">
																	<span className="symbol-label" ref={divRef} style={{background:styleLinearColorVendas}}>
																		
																		<KTSVG
																			path={item.icon}
																			className="svg-icon svg-icon-1x svg-icon-white"
																		/>
																	</span>
																</div>
	
																<div className="m-0">
																	<span className="text-white text-opacity-75 fs-8">{item.titulo}</span>
																	<span className="fw-bold text-white fs-7 d-block">{item.total}</span>
																</div>
															</div>
														);
	
													}
												})
											) : (<></>) 

											
										}
										
									</div>
								</div>
								{/* button */}
							</div>
						</div>

						<div className="col-sm-3">
							<img src={toAbsoluteUrl('/media/svg/illustrations/easy/7.svg')} className="h-200px h-lg-250px my-n6" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>

    )

}


export {CardBudgetVendas}
