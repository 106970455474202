/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import { useParams } from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import TableContract from './TableContract'
import { StickyContract } from '../../../_metronic/partials/contract/StickyContract'
import { DetailsContract } from '../../../_metronic/partials/contract/DetailsContract'
import { TypeInstallment } from '../../../_metronic/partials/contract/TypeInstallment'
import TabRouteContract from '../../../_metronic/partials/contract/TabRouteContract'

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Lista',
      path: '/contratos/contrato',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

const ContratoPage: FC<{ id: number | undefined }> = ({ id }) => (
    <>

        <div className="container-xxl ">
            <div className="d-flex flex-column flex-lg-row">
                <div className="flex-lg-row-fluid order-2 order-lg-1 mb-10 mb-lg-0">
                    <TabRouteContract id={id}/>
                </div>       
                {/* <StickyContract id={id} />  */}
            </div>
        </div>
    </>
)

const ContractWrapper: FC = () => {
  const intl = useIntl()
  const { id } = useParams();

  return (
    <>
      {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Contratos</PageTitle> */}
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <ContratoPage id={id? parseInt(id) : undefined} />
    </>
  )
}

export {ContractWrapper}
