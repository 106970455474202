import { KTSVG } from "../../../../_metronic/helpers"; 
import { useEffect, useState } from "react";

import HeaderTableRemessa from "./HeaderTableRemessa";
import { TableOptions } from "../../../interface/I_Table";
import TableSimple from "../../../../_metronic/layout/components/tables/TableSimple";
import { IListRemessa } from "../../../interface/I_Configurations"; 
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configurations',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const ArquivoRemessa = () => {

    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsInstallment: TableOptions = {
        header: HeaderTableRemessa('remessa'),
        iInterface: 'IListRemessa',
        endPoint: 'tableFileRemessa',
        methodEndPoint: 'post',
        title:'Exportação de Arquivo Bancário CNAB',
        classTable: 'table table-hover table-rounded table-striped border gy-4 gs-4',
        classHeadTr: 'fw-semibold table-striped fs-6 text-gray-800 border-bottom border-gray-200',
        classHeadTd: '',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        classTbodyTdDiv:'fw-normal text-gray-600'
    };
    
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Exportação CNAB</PageTitle>
            <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                <KTSVG path={""}/>
                <div className="d-flex flex-column">
                    <h4 className="mb-1 text-primary text-center mb-3">
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/communication/com013.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-success" path={"/media/icons/duotune/arrows/arr034.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/general/gen028.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-success" path={"/media/icons/duotune/arrows/arr034.svg"}></KTSVG>
                        <KTSVG className="svg-icon-2x svg-icon-primary" path={"/media/icons/duotune/finance/fin001.svg"}></KTSVG>
                    </h4>
                    <span>DICA: Exporte o arquivo remessa e envie-o para o banco após gerar todos os boletos do dia, assim você perderá menos tempo no sistema do banco.</span>
                    <span>Exibindo até no máximo 30 exportações de remessa em ordem a partir da mais antiga e não marcada como enviada ao banco.</span>
                </div>
            </div>
            <div className="card card-flush mb-5 mb-xl-10">
                <TableSimple options={optionsInstallment} />
            </div>
        </>
    );
}