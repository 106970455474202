import { FC, useEffect, useState } from "react";
import { TableOptions } from "../../../../app/interface/I_Table";
import { IDetailsInstallment } from "../../../../app/interface/I_Payments";
import { detailsParcel, getDataClient } from "../../../../app/modules/payment/payments";
import TableSimple from "../../../layout/components/tables/TableSimple";
import HeaderTablePayment from "../../../../app/pages/pagamentos/table/HeaderTablePayment";
import { IClient } from "../../../../app/interface/I_Client";

export const OverviewParcel: FC<{ id: number | string | undefined }> = ({ id }) => {

    const [parcelDataDetails, setParcelDataDetails] = useState<IDetailsInstallment | null>(null);
    const [dataClient, setDataClient] = useState<IClient | null>(null);

    useEffect(() => {
        async function fetchData() {

            // detalhes da parcela
            try {
                const responseDetails = await detailsParcel(id || -1);
                setParcelDataDetails(responseDetails.data);
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseDetails:", error);
            }          
             
        }
        fetchData();
      }, [id]);

      useEffect(() => {
        async function fetchData() {

            // dados do cliente
            try {
                const responseClient = await getDataClient(id || -1);
                setDataClient(responseClient.data);
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseDetails:", error);
            }            
                     
            
        }
        fetchData();
      }, [id]);

      
      
      // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsParcel: TableOptions = {
        header: HeaderTablePayment('installment'),
        iInterface: 'associatedBilling', // cobrança associada
        endPoint: 'getAssociatedBilling',
        methodEndPoint: 'get',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'min-w-150px',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        classTbodyTdDiv:'fw-normal text-gray-600'
    };


    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <div className="card-header">
                
                <div className="card-title">
                    <h2 className="fw-bold">Detalhe do Contrato</h2>
                </div>
            </div>

            <div className="card-body pt-3">
                <div className="mb-10">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Dados gerais:</h5>

                    <div className="d-flex flex-wrap py-5">
                        <div className="flex-equal me-5">
                            <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-400 min-w-175px w-175px">Número de parcela:</td>
                                        <td className="text-gray-800 min-w-200px">
                                            {parcelDataDetails?.numberInstallment || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Tipo de parcela:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.typeInstallment || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Situação:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.situationInstallment || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Valor esperado:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.expectedValue || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Valor pago:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.valuePayment || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Data de pagamento:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.payday || '...'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-400">Data de transferência:</td>
                                        <td className="text-gray-800">
                                            {parcelDataDetails?.transferDate || '...'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Participantes */}
                <div className="mb-0">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Cobranças associadas à parcela:</h5>
                    <TableSimple options={optionsParcel} />
                </div>
                
                <div className="mb-10">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Dados do cliente:</h5>

                    <div className="d-flex flex-wrap py-5">
                        <div className="flex-equal me-5">
                            <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-400 min-w-175px w-175px">Nome:</td>
                                        <td className="text-gray-800 min-w-200px">
                                            {dataClient?.name || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Email:</td>
                                        <td className="text-gray-800">
                                            {dataClient?.email || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Telefone:</td>
                                        <td className="text-gray-800">
                                            {dataClient?.phone || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Contrato:</td>
                                        <td className="text-gray-800">
                                            {dataClient?.contract || '...'}
                                        </td>
                                    </tr>

                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


