import React, { useContext, useState } from 'react';
import DateRangePickerComponent from "../../layout/components/dates/DateRangePickerComponent";
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";

import { useDispatch } from 'react-redux';
import { setFilter } from '../../../app/redux/filter/filterSlice'; 

const FormFilterDashboard: React.FC = () => {

    const dispatch = useDispatch();

    interface Option {
        value: string;
        label: string;
    }

    const optionsProduto: Option[] = [
        { value: "17", label: "Maranello" },
        { value: "33", label: "Residencial Farone Zanaga" },
        { value: "32", label: "Residencial sagrada familia" }
    ];

    const optionsStatus: Option[] = [
        { value: "1", label: "Vencido" },
        { value: "2", label: "Recebido" },
        { value: "3", label: "Atrasado" }
    ];

    // Init get selection dates
    const [dates, setDates] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
      });

    function handleDateRangeSelection(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        setDates({ start_date: start_date, end_date: end_date });
        console.log('handleDateRangeSelection seachGlobals ',event, picker, start_date, end_date)
    }

    // End get selection dates

    const [selectedOptionProduto, setSelectedOptionProduto] = useState<Option | null>(null);
    const [selectedOptionStatus, setSelectedOptionStatus] = useState<Option | null>(null);

    const handleChangeProduto = (option: Option | null) => {
        setSelectedOptionProduto(option); 
        console.log('produto ', option)
    };

    const handleChangeStatus = (option: Option | null) => {
        setSelectedOptionStatus(option);
        console.log('status ', option)
    };

    // const { formData, setFormData } = useContext(FormContext);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        const formDataReact = new FormData();
        // console.log("Datas selecionadas: ", dates.start_date, " - ", dates.end_date);
        
        const formDataJSON = {
            start_date: dates.start_date.format('YYYY-MM-DD'),
            end_date: dates.end_date.format('YYYY-MM-DD'),
            id_product: '',
            status:''
        };

        if (selectedOptionProduto) {
            formDataJSON.id_product = selectedOptionProduto.value;
        }

        if (selectedOptionStatus) {
            formDataJSON.status = selectedOptionStatus.value;
        }       
        
        
        // setFormData(formDataJSON);
        console.log('handleSubmit',formDataJSON, dates.start_date.toISOString());

        const { start_date, end_date, id_product, status } = formDataJSON;

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ start_date, end_date, id_product, status }));
        
    }

    const resetFilterForm = () => {

        const { start_date, end_date, id_product, status } = {start_date : '', end_date : '', id_product : '', status : ''};

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ start_date, end_date, id_product, status }));
    }

    return (
        
        // <FormContext.Provider value={{ formData, setFormData }}>
            <form id="form_filter_dashboard" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Período</label>
                        <DateRangePickerComponent onDateRangeSelection={handleDateRangeSelection} />
                        <p>
                        Selected Dates:{" "}
                        {`${dates.start_date} - ${dates.end_date}`}
                        </p>
                        {/* Rest of your component code */}
                    </div>
                    <div className='mb-10 row'>
                        <div className="col-md-6 fv-plugins-icon-container">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={selectedOptionProduto}
                                    onChange={handleChangeProduto}
                                    options={optionsProduto}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 fv-plugins-icon-container">
                            <label className='form-label fw-bold'>Status</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um status"
                                    value={selectedOptionStatus}
                                    onChange={handleChangeStatus}
                                    options={optionsStatus}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form>
        // </FormContext.Provider> 
    );
};

export default FormFilterDashboard;
