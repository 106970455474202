/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
    const intl = useIntl()

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />
            {/* <SidebarMenuItemWithSub
                to='/contratos'
                title='Contratos'
                fontIcon='bi-chat-left'
                icon='/media/icons/duotune/general/gen005.svg'
            > */}
            <SidebarMenuItem 
                to='/contract/list' 
                title='Contratos' 
                // hasBullet={true} 
                icon='/media/icons/duotune/general/gen005.svg'
            />
            {/* </SidebarMenuItemWithSub> */}
            
            
            <SidebarMenuItem
                to='/product'
                icon='/media/icons/duotune/ecommerce/ecm008.svg'
                title={'Produtos'}
                fontIcon='bi-app-indicator'
            />

            <SidebarMenuItem 
                to='/units' 
                title='Unidades' 
                // hasBullet={true} 
                icon='/media/icons/duotune/layouts/lay002.svg'
            />

            
            <SidebarMenuItem 
                to='/payments' 
                title='Pagamentos'
                icon='/media/icons/duotune/finance/fin002.svg'
            />

            <SidebarMenuItem
                to='/configurations'
                icon='/media/icons/duotune/coding/cod001.svg'
                title={'Configuração'}
                fontIcon='bi-app-indicator'
            />

        </>
    )
}

export {SidebarMenuMain}
