/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import TableContract from './TableContract'
import getHeaderForTypeContract from "../../../_metronic/partials/contract/HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table"; 
import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'
import { IFormContract } from '../../interface/I_Contract'
import { FormInsertContract } from '../../../_metronic/partials/contract/modal/contract/FormInsertContract'
import { FormEditContract } from '../../../_metronic/partials/contract/modal/contract/FormEditContract'


const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Lista',
      path: '/contratos/lote',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]


const ContratoPage: FC = () => {
    
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [tableData, setTableData] = useState<IFormContract[]>([]);

    const [formValues, setFormValues] = useState<IFormContract>({
        id: '0',
        id_client: '0',
        id_product: '0',
        id_unit: '0',
        name: '',
        name_product:'',
        name_unit: '',
        description: '',
        value:'...',
        contract_expiration:'...',
        next_payment:'...'
    });

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setIsModalInsertOpen(true);
    };

     /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id || '0',
            id_client: row.id_client || '0',
            id_product: row.id_product || '0',
            id_unit: row.id_unit || '0',
            name: row.name[1] || '',
            name_product:row.name_product || '',
            name_unit:row.name_unit || '',
            description:row.description || '',
            value:'...',
            contract_expiration:'...',
            next_payment:'...'
        });

        setIsModalEditOpen(true);
    };    
    
    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };

    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    const handlerCallbackFormSubmit = (formData :IFormContract) => {

        if( formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "")
        {
            const updatedContract = {
                ...formData,
                value: '...',
                status: 'Ativo',
                next_payment: '...',
                contract_expiration: '...',
            };

            const updatedTableData = [updatedContract];
          
            setTableData(updatedTableData);

            setIsModalInsertOpen(false);
            setIsModalEditOpen(false);
        }
    }
    
    const optionsInstallment: TableOptions = {
        // id:id,
        header: getHeaderForTypeContract('contract', handleEdit),
        iInterface: 'contract',
        endPoint: `tableContract`,
        methodEndPoint: 'post',
        title:'Lista de Contratos',
        classHeadTd: 'min-w-150px',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        isHidePaginate : true,
        funDeleteInMass: 'deleteContractInMassa',
        // funChangeInMass: 'changeContractInMassa',
        textDelete: 'Contratos',
    };
  
    return (
   
        <div className="app-container  container-xxl ">
            <div className="d-flex flex-column flex-lg-row ms-n11">
                {/* <TableContract api={`tableContract`} type={`contrato`}/> */}
                {/* <TableSimple options={optionsContract} /> */}
                <TableAdvanced 
                    options={optionsInstallment} 
                    // optionsActionInMassa={optionsActionInMassa}
                    tableData={tableData}
                    handleInsert={handleInsert}
                />

                {isModalEditOpen && 
                    <FormEditContract 
                        formValues={formValues}
                        closeModal={closeModalEdit}
                        onFormSubmit={handlerCallbackFormSubmit}
                    />
                }                
            
                {isModalInsertOpen && 
                    <FormInsertContract
                        closeModal={closeModalInsert}
                        onFormSubmit={handlerCallbackFormSubmit}
                        // handlerSaveForm={confirmSaveForm} 
                        // formValues={formValuesContract}
                        // handleChange={handleChange}
                    />
                }
                
            </div>        
        </div>
    );
}

const ListContractWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Contratos</PageTitle>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <ContratoPage />
    </>
  )
}

export {ListContractWrapper}
