import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';

const FormFilterProduct: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    
    const optionsTypePlan: Option[] = [
        { value: "H", label: "Terreno" },
        { value: "V", label: "Predio" }
    ];


    const [selectedProduto, setSelectedProduto] = useState('');
    const [selectedTypePlan, setSelectedTypePlan] = useState('');

    
    console.log('produstos query ', products)
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    interface Option {
        value: string;
        label: string;
    }

    
    
    console.log('selectedProduto ', selectedProduto)   
   

    const handleSelectChange = (value: string, fieldName:string) => {
        if(fieldName === 'product')
        {
            setSelectedProduto(value)
        }else{
            
            setSelectedTypePlan(value)
            console.log('tipo', fieldName, value)
        }
    }; 


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        
        const formDataJSON = {
           
            id_product: selectedProduto,
            type_plan: selectedTypePlan,
        };        

        const { id_product, type_plan } = formDataJSON;

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ id_product, type_plan }));
        
    }

    const resetFilterForm = () => {

        const { 
            id_product,
            type_plan,
        } = {
            id_product: '',
            type_plan: '',
        };

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ id_product, type_plan }));
    }

    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={selectedProduto !== '0' && selectedProduto !== '' ? { value: selectedProduto, label: optionsProduct.find(option => option.value === selectedProduto)?.label } : null}
                                    
                                    onChange={(e) => handleSelectChange(e?.value || '', 'id_product')}
                                    options={optionsProduct}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Tipo</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um tipo"
                                    value={selectedTypePlan !== '0' && selectedTypePlan !== '' ? { value: selectedTypePlan, label: optionsTypePlan.find(option => option.value === selectedTypePlan)?.label } : null}
                                    
                                    onChange={(e) => handleSelectChange(e?.value || '', 'type_plan')}
                                    options={optionsTypePlan}
                                />
                            </div>
                        </div>
                    </div>                    
                    
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterProduct;
