import Uppy from "@uppy/core";
import { Dashboard, DashboardModal } from "@uppy/react";
// import AwsS3Multipart from "@uppy/aws-s3-multipart";
import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";
import ScreenCapture from "@uppy/screen-capture";
import Webcam from "@uppy/webcam";
import Compressor from "@uppy/compressor";
// import Tus from '@uppy/tus';
import {pt_BR} from "../../../../i18n/uppy/pt_BR";
import { Upload } from "../../../../../app/interface/I_Files";
import {getAuth} from '../../../../../app/modules/auth/core/AuthHelpers';
import Swal from "sweetalert2";

// Não se esqueça do CSS: núcleo e dos componentes de interface do usuário + plug-ins que você está usando.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import axios from "axios";


export const Importacao:React.FC<{ propsFiles: Upload, onFile:any }> = ({ propsFiles, onFile }) => {
    
    const API_URL_UPLOAD = `${process.env.REACT_APP_API_URL}/module/${propsFiles.endPoint}`  
    const API_URL_DELETE = `${process.env.REACT_APP_API_URL}/module/${propsFiles.urlDelete}`  

    const uppy = new Uppy({
        debug: propsFiles.isDebug,
        id: propsFiles?.idUppy ||'importacao',
        autoProceed: propsFiles.isAutoProceed,
        allowMultipleUploads: propsFiles.isAllowMultipleUploads,
    });
    
    uppy.setOptions({
        locale: {
          strings: pt_BR
        },
        restrictions:{
            allowedFileTypes:propsFiles.fileTypes,
        },
    });
    
    uppy.use(XHRUpload, { endpoint: API_URL_UPLOAD, limit: 1,
        fieldName: 'upload',
        headers: {
            'Authorization': `Bearer ${getAuth()?.api_token}`
        },
    });    

    if( propsFiles.plugins )
    {
        if( propsFiles.plugins.includes('ImageEditor') )
        {
            uppy.use(ImageEditor, {
                id: propsFiles?.idImageEditor || 'ImageEditor',
                quality: 0.8,
            });
        }
        
        if( propsFiles.plugins.includes('ScreenCapture') )
        {
            uppy.use(ScreenCapture, {
                id: propsFiles?.idScreenCapture || 'ScreenCapture'
            }).use(Compressor);;
        }

        
        if( propsFiles.plugins.includes('Webcam') )
        {
            uppy.use(Webcam, {
                id: propsFiles?.idWebcam || 'Webcam',                
                showVideoSourceDropdown: true,
                showRecordingLength: true
            });
        }

        // processa os arquivos para adicioanr ao carregar o uppy e adicona metada dados com id para recuperar depois
        if(propsFiles.filesData)
        {
            propsFiles.filesData.map((file: any, indexFiles: number) => {
                fetch(`data:image/jpeg;base64,${file.content}`)
                .then((response) => response.blob()) 
                .then((blob) => {
                    uppy.addFile({
                        id:file.id,
                        name: file.name,
                        type: file.type,
                        data: blob
                    });

                    uppy.getFiles().forEach((setFile, indexFile) => {
                        if(indexFiles === indexFile)
                        {
                            uppy.setFileMeta(setFile.id, { id: file.id })
                        }

                        uppy.setFileState(setFile.id, { 
                          progress: { uploadComplete: true, uploadStarted: true },
                        })

                        
                            console.log('files ', file)
                    })
                });
            });            
        }        
    }

    uppy.on('upload-success', (fileId, response: any): any => {

        onFile(true);
        if(propsFiles.isMessageReturn)
        {
            Swal.fire({
                icon: response.body?.response?.messages?.icon || 'info',
                title: response.body?.response?.messages?.title,
                text: response.body?.response?.messages?.text
            });
        }
        
    });
    
    uppy.on('file-removed', (file, reason) => {
        if (reason === 'removed-by-user') {
            deleteFile(file);
        }
    });

    // deleta o arquivo do servidor 
    async function deleteFile(file:any) {

        try {
            if (propsFiles.urlDelete) {
                await axios.delete(`${API_URL_DELETE}/${file.meta.id}`);
                uppy.removeFile(file.meta.id);
                console.log('delete ', file.meta.id)
                onFile(true)
            }
        } catch (e) {
            console.log(e);
            return;
        }
    }

    return (
        <Dashboard
            className="z-index-1"
            uppy={uppy}
            plugins={propsFiles.plugins}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails={propsFiles.isShowProgressDetails}
            hideUploadButton={propsFiles.isHideUploadButton}
            target="body"
            width={propsFiles.width}
            height={propsFiles.height}
            showRemoveButtonAfterComplete={true}
            metaFields={[
                { id: 'name', name: 'rename', placeholder: 'Renomear arquivo' },
                { id: 'caption', name: 'description', placeholder: 'Descreva do que se trata o arquivo' }
            ]}
        />
    )
}

