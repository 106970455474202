import React, { useEffect } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import Select, {OptionsOrGroups, GroupBase} from "react-select";
import { FormProps, FormValues } from "../../../../../app/interface/I_Parcel";
import { formatarPercentage, formatarValor } from "../../../../helpers/functions/numbers";
import { NumericFormat } from 'react-number-format';


  
interface Option {
    value: string;
    label: string;
}
export const FormInsertParcel = ({ 
    closeModal,
    handlerSaveForm,
    formValues,
    handleChange 
}: FormProps) => {
  
    // forma de pagamento
    const optionsTypePayment: Option[] = [
        { value: "transferencia", label: "Transferência" },
        { value: "boleto", label: "Boleto" },
        { value: "pix", label: "Pix" }
    ];

    // situação
    const optionsStatus: Option[] = [
        { value: "pendente", label: "Pendente" },
        { value: "pago", label: "Pago" },
        // { value: "Recorrente", label: "Recorrente" }
    ];

    // recorrencia
    const optionsRecurrence: Option[] = [
        { value: "0", label: "Não" },
        { value: "1", label: "Sim" }
    ];

    // periodicidade
    const optionsFrequency: Option[] = [
        { value: "1D", label: "Diário" },
        { value: "1W", label: "Semanal" },
        { value: "15D", label: "Quizenal" },
        { value: "1M", label: "Mensal" },
        { value: "2M", label: "Bimestral" },
        { value: "3M", label: "Trimestral" },
        { value: "6M", label: "Semestral" },
        { value: "1Y", label: "Anual" }
    ];

    // tipo parcela
    const optionsTypeParcel: Option[] = [
        { value: "AV", label: "Á vista" },
        { value: "CO", label: "Comissão Corretor" },
        { value: "COR", label: "Comissão Corretor (Repasse)" },
        { value: "CH", label: "Comissão Interna" },
        { value: "CHR", label: "Comissão Interna (Repasse)" },
        { value: "DS", label: "Desconto" },
        { value: "SI", label: "Entrada" },
        { value: "FG", label: "FGTS" },
        { value: "FB", label: "Financiamento Bancário" },
        { value: "IT", label: "Intermediaria"},
        { value: "PC", label: "Parcela de chaves"},
        { value: "PM", label: "Parcela Mensal" },
        { value: "SB", label: "Subsídio" },
        // { value: "TR", label: "Trimestral" },
        // { value: "SM", label: "Semestral" },
        // { value: "AN", label: "Anual" },
    ];
    
    // Indice de reajustes
    const optionsReadjustmentIndex: Option[] = [
        { value: "nenhum", label: "Nenhum" },
        { value: "IGP-M", label: "IGP-M" },
        { value: "IPCA", label: "IPCA" },
        { value: "INCC", label: "INCC" },
    ];

    // gerar parcelas de cobrança
    const optionsGenerateBillingInstallments: Option[] = [
        { value: "sim", label: "Sim" },
        { value: "nao", label: "Não" },
    ];

    useEffect(() => {
        const formattedValue = formatarValor(formValues.value);
        handleChange("value", formattedValue);

        // const formattedInterestRate = formatarPercentage(formValues.interest_rate);
        // handleChange("interest_rate", formattedInterestRate);
    }, []);

    
    const handleInputChange = (
        value: string,
        fieldName: keyof FormValues
      ) => {

        console.log('new valor format ', value)
        
        handleChange(fieldName, value);
    };

    
    const handleSelectChange = <K extends keyof FormValues>(selectedOption: Option | null,
        fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        
        console.log('handleSelectChange ',fieldName, selectedOption, value)
        handleChange(fieldName, value);
    };    

    
    console.log('formValues ', formValues)

    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="modaBody">
                            <div className="container-fluid">
                                <div className="d-block" id="kt_app_engage_prebuilts_view_image">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" id="" role="tabpanel">
                                            <div
                                                className="pe-12 me-n12"                                                
                                            >
                                                <div className="row gy-10">
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Parcela</label>
                                                        <div className='d-flex'>
                                                            <input 
                                                                className="form-control" 
                                                                name="number_parcel"
                                                                pattern="[1-9][0-9]*"
                                                                value={formValues.number_parcel}
                                                                readOnly={formValues?.id !== '' && formValues?.id !== '0' && formValues?.id !== 0 ? true : false  }
                                                                onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        const isValidInput = /^[1-9][0-9]*$/.test(inputValue);
                                                                    
                                                                        if (isValidInput || inputValue === "") {
                                                                            handleInputChange(inputValue, "number_parcel");
                                                                        }else{
                                                                            handleInputChange('1', "number_parcel");
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Recorrência</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                name="is_recurring"
                                                                value={formValues.is_recurring !== '' ? { value: formValues.is_recurring, label: optionsRecurrence.find(option => option.value === formValues.is_recurring)?.label } : null}

                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "is_recurring")}
                                                                options={optionsRecurrence}
                                                            />
                                                        </div>
                                                    </div>

                                                    
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Períodicidade</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                name="frequency"
                                                                value={formValues.frequency !== '' ? { value: formValues.frequency, label: optionsFrequency.find(option => option.value === formValues.frequency)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "frequency")}
                                                                options={optionsFrequency}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Forma de pargamento</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Forma de pargamento"
                                                                name="form_payment"
                                                                value={formValues.form_payment !== '' ? { value: formValues.form_payment, label: optionsTypePayment.find(option => option.value === formValues.form_payment)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "form_payment")}
                                                                options={optionsTypePayment}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Tipo de parcela</label>
                                                        <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                name="type_parcel"
                                                                value={formValues.type_parcel !== '' ? { value: formValues.type_parcel, label: optionsTypeParcel.find(option => option.value === formValues.type_parcel)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "type_parcel")}
                                                                options={optionsTypeParcel}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Vencimento</label>
                                                        <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                        <div className='d-flex'>
                                                            <input 
                                                                type="date" 
                                                                className="form-control" 
                                                                name="first_due_date"
                                                                value={formValues.first_due_date || ''}
                                                                onChange={(e) => {
                                                                        handleInputChange(e.target.value, "first_due_date")
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Valor</label>
                                                        <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                        <div className='d-flex'>
                                                            <input 
                                                                className="form-control" 
                                                                name="value"
                                                                value={formValues.value}
                                                                onChange={(e) => {
                                                                    
                                                                        const newValue = formatarValor(e.target.value).toString()
                                                                        handleInputChange(newValue, "value")
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    { formValues?.table && formValues.table === 'parcel' ? 
                                                    (
                                                        <div className="col-lg-3  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Situação</label>
                                                            <div className='d-flex'>
                                                                <Select
                                                                    className="w-450px"
                                                                    placeholder="Selecione"
                                                                    name="status"
                                                                    value={formValues.status !== '' ? { value: formValues.status, label: optionsStatus.find(option => option.value === formValues.status)?.label } : null}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "status")}
                                                                    options={optionsStatus}
                                                                />
                                                            </div>
                                                        </div>

                                                    ) : 
                                                    (<></>)
                                                    }
                                                    
                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Taxa de Juros</label>
                                                        <span className="ms-1 fs-9 text-danger">(Obrigatório)</span>
                                                        <div className='d-flex'>
                                                            <input 
                                                                className="form-control" 
                                                                name="interest_rate"
                                                                pattern="[1-9][0-9]*"
                                                                value={formValues.interest_rate}
                                                                onChange={(e) => {
                                                                        const inputValue = e.target.value;
                                                                        // const isValidInput = /^[1-9][0-9]*$/.test(inputValue);                                                                        
                                                                        const newValue = formatarPercentage(inputValue);
                                                                        handleInputChange(newValue, "interest_rate");
                                                                    }
                                                                }
                                                            />

                                                            {/* <NumericFormat
                                                                value={formValues.interest_rate}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                suffix="%"
                                                                allowNegative={false}
                                                                allowLeadingZeros={false}
                                                                onValueChange={({floatValue}) => {
                                                                    console.log('percent ', floatValue)
                                                                    let _number = typeof floatValue === 'undefined' ? '0' : floatValue.toString();
                                                                        handleInputChange(_number, "interest_rate");
                                                                }}
                                                                customInput={React.forwardRef((props: any, ref) => (
                                                                    <input className="form-control" name="interest_rate"  {...props} ref={ref} />
                                                                ))}
                                                                /> */}

                                                            
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Índice de Reajuste</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                name="readjustment_index"
                                                                value={formValues.readjustment_index !== 'Nenhum' ? { value: formValues.readjustment_index, label: optionsReadjustmentIndex.find(option => option.value === formValues.readjustment_index)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "readjustment_index")}
                                                                options={optionsReadjustmentIndex}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3  fv-plugins-icon-container">
                                                        <label className='form-label fw-bold'>Gerar parcelas de cobrança</label>
                                                        <div className='d-flex'>
                                                            <Select
                                                                className="w-450px"
                                                                placeholder="Selecione"
                                                                isDisabled={formValues?.id !== '' && formValues?.id !== '0' && formValues?.id !== 0 && formValues.generate_billing_installments !== 'nao' ? true : false  }
                                                                name="generate_billing_installments"
                                                                value={formValues.generate_billing_installments !== '' ? { value: formValues.generate_billing_installments, label: optionsGenerateBillingInstallments.find(option => option.value === formValues.generate_billing_installments)?.label } : null}
                                                                onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "generate_billing_installments")}
                                                                options={optionsGenerateBillingInstallments}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="modal-footer border-0">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="button" className="btn btn-success me-4" onClick={handlerSaveForm}>
                                                    Salvar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}