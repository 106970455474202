import { Link, useLocation } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../helpers"

export const TabHeaderPix = () => {
    
    const location = useLocation();
    console.log('TabHeadPix location.pathname ', location.pathname)
    return (
        <div
            className="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10"
            style={{backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/4.png')})`, backgroundSize: 'auto calc(100% + 10rem)', backgroundPositionX: '100%'}}
        >
            <div className="card-header pt-10">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-circle me-5">
                        <div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
                            <i className="ki-duotone ki-abstract-47 fs-2x text-primary"><span className="path1"></span><span className="path2"></span></i>
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <h2 className="mb-1">Configuraçao de conteúdo Pix</h2>
                        <div className="text-muted fw-bold"><span className="mx-3">QrCode</span><span className="mx-3">|</span> Atribuição de dados Pix <span className="mx-3">|</span>Geração em lotes</div>
                    </div>
                </div>
            </div>

            <div className="card-body pb-0">
                <div className="d-flex overflow-auto h-55px">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap">
                        <li className="nav-item">
                            <Link
                                className={
                                    `nav-link text-active-primary pb-4 me-6 ` +
                                    (location.pathname === `/configurations/pix/settings`  && 'active')
                                
                                }
                                
                                to={`/configurations/pix/settings`}
                            >
                                Configuração Pix
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link
                                className={
                                    `nav-link text-active-primary pb-4 me-6 ` +
                                    (location.pathname === `/configurations/pix/qrcode` && 'active')                                
                                }
                                
                                to={`/configurations/pix/qrcode`}
                            >
                                Gerar Manual
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}