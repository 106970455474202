import { KTSVG } from "../../../../_metronic/helpers"
import { Importacao } from "../../../../_metronic/layout/components/configurations/importacao/Importacao" 
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Upload } from "../../../interface/I_Files"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: '/configurations',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

export const ArquivoRetorno = () => {

    const proposUpload: Upload = {
        idUppy:'importacao',
        idImageEditor:'ImageEditor',
        plugins:['ImageEditor', 'ScreenCapture', 'Webcam'],
        isDebug:true,
        isAutoProceed: false,
        isAllowMultipleUploads: true,
        isShowProgressDetails:false,
        isHideUploadButton:false,
        fileTypes: ['.RET', '.ret'],
        endPoint: 'arquivoRetorno',
        width: 'auto',
        height: '350px',        
        isMessageReturn:true
    }
    const handleFile = (isLoad:boolean) => {
        
    };

    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Importação CNAB</PageTitle>
            <div className="col-xl-12 mb-xl-10">
                <div className="alert alert-primary d-flex align-items-center p-5 mb-10">
                    <KTSVG path={""}/>
                    <div className="d-flex flex-column">
                        <h4 className="mb-1 text-primary">Área de Importação de Arquivo Retorno</h4>
                        <span>Importe seus arquivo retorno do banco.</span>
                    </div>
                </div>
            
			    <div className="card">
				
                    <div className="card-body">
                        <div className="row align-items-center">
                            <Importacao 
                                propsFiles={proposUpload}                                
                                onFile={handleFile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}