
import { Link } from "react-router-dom"
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers"
import { ICardConfiguration } from "../../interface/I_Configurations"
export const ContentGeneral: React.FC = () => {

    const ComponentConfuguration: Array<ICardConfiguration> = [
        {
            id:'47aad2c3a8a',
            title:'Arquivo Retorno',
            description:'Importe seu arquivo de retorno',
            icon:'/media/icons/duotune/files/fil010.svg',
            background:'/media/outros/abstract-1.svg',
            // icon:'/media/svg/files/upload.svg',
            routes:'/configurations/import'
        },
        {
            id:'a50c1efbf417',
            title:'Arquivo Remessa',
            description:'Exporte seu arquivo remessa',
            icon:'/media/icons/duotune/files/fil021.svg',
            background:'/media/outros/abstract-2.svg',
            routes:'/configurations/export'
        },
        {
            id:'0f5ba114f8fd',
            title:'Pix',
            description:'Configure e gere Pix',
            icon:'/media/icons/duotune/general/gen022.svg',
            background:'/media/outros/abstract-4.svg',
            routes:'/configurations/pix'
        }
    ]

    return (

        <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
        
            {            
                ComponentConfuguration.map((value) => (
                    
                    <div className="col-md-4 col-lg-4 col-xl-3 col-sm-12 card card-flush p-6 pb-5 mw-100 ms-3" style={{backgroundImage:`url(${toAbsoluteUrl(value?.background || '')})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top'}}  key={value.id}> 
                        <Link  to={value.routes}> 
                        {/* <a href={value.routes} className="text-gray-800 text-hover-primary d-flex flex-column"> */}
                            <div className="card-body text-center">
                                <KTSVG className="svg-icon svg-icon-5x" path={value.icon}/>
                                <div className="mb-2">
                                    <div className="text-center">                         
                                        <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">{value.title}</span>
                                        <span className="text-gray-400 fw-semibold d-block fs-6 mt-n1">{value.description}</span>              
                                    </div>                                    
                                </div>
                            </div>
                        {/* </a>  */}
                        </Link>               
                    </div>                    
                )) 
            }
        </div>

    )
}