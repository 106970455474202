import { Link } from "react-router-dom";
import { IHeaderWithCell, IHeader } from "../../../app/interface";
  
export const HeadertTableProduct = (type: string, handleEdit?: (row: any) => void): IHeaderWithCell[] => {
    switch (type)
    {
        case 'product': // carne
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'product_plan' },
                { Header: 'Cidade', accessor: 'city' },
                { Header: 'Proprietário', accessor: 'name_owner' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        <div>

                            <Link className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary"  to={`${row.link}`}>
                                Editar
                            </Link>
                        </div>
                    )
                }
            ];
        
        default:
            return [
                { Header: 'Produto', accessor: 'name' },
                { Header: 'Tipo', accessor: 'type' },
                { Header: 'Cidade', accessor: 'city' },
                { Header: 'Proprietário', accessor: 'owner' },
                { Header: 'Ações', 
                    accessor: (row: any) => (
                        <div>
                            <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                                Editar 
                            </button>
                        </div>
                    )
                }
            ];
    }
}; 

export default HeadertTableProduct