import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilterGLobals } from '../../interface/I_Filter'; 

// const initialState: IFilterGLobals = {
//     full_date:'',
//     start_date:'',
//     end_date:'',
//     due_date:'',
//     product:'',
//     id_product: '',
//     id_unit: '',
//     status:'',
//     name:'',
//     client:'',
//     end_point:'',
//     type_plan:'',
//     period_1: {
//         start_date: '',
//         end_date: '',
//     },
//     period_2: {
//         start_date: '',
//         end_date: '',
//     },
//     value: '',
//     date_payment: '',
//     assessment: '',
//     type_payment:'',
// };

interface IFilterState {
    [key: string]: any;
}


const initialState: IFilterState = {};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<IFilterState>
        ) => {
            // const {
            //     start_date,
            //     end_date,
            //     due_date,
            //     id_product,
            //     id_unit,
            //     status,
            //     period_1,
            //     period_2,
            //     name,
            //     type_plan,                
            //     value,
            //     date_payment,
            //     assessment,
            //     type_payment,
            // } = action.payload;

            
            // state.start_date = start_date;
            // state.end_date = end_date;
            // state.due_date = due_date; // vencimento
            // state.id_product = id_product;
            // state.id_unit = id_unit;
            // state.status = status;
            // state.period_1 = period_1;
            // state.period_2 = period_2;
            // state.name = name;
            // state.type_plan = type_plan;
            // state.value = value;
            // state.date_payment = date_payment;
            // state.assessment = assessment;
            // state.type_payment = type_payment;

            const filterPayload = action.payload;
            Object.keys(filterPayload).forEach((key) => {
                state[key] = filterPayload[key];
            });

        },
    },
});

export const { setFilter } = filterSlice.actions;
export default filterSlice.reducer;
