import { IHeaderWithCell } from "../../../interface";
  
/**
 * 
 * @param type
 *  
    beneficiary:string;
    bankAccount:string;
    nameCliente:string;
    numberBoletos:string;
    createDate: string;
    numberRemessa: string;
    nameFile: string;
    status:string;
 * @returns 
 */
export const HeaderTableRemessa = (type: string): IHeaderWithCell[] => {
    switch (type) {
        case 'remessa': // lista de pagamentos
            return [
                { Header: 'Beneficiário', accessor: 'beneficiary' },
                { Header: 'Conta', accessor: 'bankAccount' },
                { Header: 'Boletos', accessor: 'numberBoletos' },
                { Header: 'Criação', accessor: 'createDate' },
                { Header: 'Remessa N°', accessor: 'numberRemessa' },
                { Header: 'Arquivo', accessor: 'nameFile' },
                // { Header: 'Status', accessor: 'status' },
                // { Header: 'Ações', 
                //     accessor: (row: any) => (
                        
                //         <div>
                //             <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                //                 Ver Boleto 
                //             </button>
                //         </div>
                //     )
                // },
            ];

            
        default:
            return [
                { Header: 'Beneficiário', accessor: 'beneficiary' },
                { Header: 'Conta', accessor: 'bankAccount' },
                { Header: 'Boletos', accessor: 'expiration' },
                { Header: 'Criação', accessor: 'createDate' },
                { Header: 'Remessa N°', accessor: 'numeberRemessa' },
                // { Header: 'Status', accessor: 'status' },
                // { Header: 'Ações', 
                //     accessor: (row: any) => (
                        
                //         <div>
                //             <button className="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" onClick={() => console.log(`Ação para ID  `, row)}>
                //                 Ver Boleto 
                //             </button>
                //         </div>
                //     )
                // },
            ];
    }
}; 

export default HeaderTableRemessa