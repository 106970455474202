import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { KTSVG } from "../../../../helpers";
import Select from "react-select";
import { FormContractProps, IFormContract } from "../../../../../app/interface/I_Contract";
// import { getClientByName } from "../../../../../app/modules/client/_requestClient";
import { useAuth } from "../../../../../app/modules/auth";
import { TagifyCustom } from "../../../../layout/components/buttons/TagifyCustom";
import * as Yup from 'yup'
// import {Formik, Form, FormikValues} from 'formik'
import {schemaContract} from "./SchemaForm"; 
// import { IFormUnit } from "../../../../../app/interface/I_Unit";
import { getAllProduct } from "../../../../../app/modules/product/requestProduct";
import { useMemo } from 'react';
import { getUnitsByIdProduct } from "../../../../../app/modules/unit/requestUnits";
import { formatarPercentage, formatarValor } from "../../../../helpers/functions/numbers";
import { insertContractAPI } from "../../../../../app/modules/contract/_request";
import Swal from "sweetalert2";

export const FormInsertContract: React.FC<FormContractProps> = ({ 
    // formValues,
    closeModal,    
    onFormSubmit
}) => {
    
    
    const {auth, logout, setCurrentUser} = useAuth();

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_product: string;
        id_unit: string;
    }

    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);
    const [optionsUnit, setOptionsUnit] = useState<Option[]>([]);
    const [nameClient, setNameClient] = useState('');

    const [formData, setFormData] = useState({
        id: '0',
        id_client: '0',
        id_product: '0',
        id_unit: '0',
        name:'',
        name_product:'',
        name_unit:'',
        description:'',
    });

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({        
        id_client: '',
        id_product: '',
        id_unit: '',
        description:''
    });

    const handleContractChange = useCallback((e:any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,            
            [name]: value
        }));
    }, []);

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        const textSelected = selectedOption ? selectedOption.label : "";

        if(fieldName === 'id_product')
        {            
            fetchUnits(value);

            setFormData(prevState => ({
                ...prevState,                
                [fieldName]: value.toString(),
                ['name_product']: textSelected
            }));

        }else{
            
            setFormData(prevState => ({
                ...prevState,
                [fieldName]: value.toString(),
                ['name_unit']: textSelected,
            }));
        }
    };    

    const fetchProducts = async () => {
        if (auth && auth.api_token)
        {
            const response = await getAllProduct(auth);
          
            if (response && response.data && Array.isArray(response.data))
            {
                const newOptionsProduct = response.data.map((product: { id: any; name: any; }) => ({
                    value: product.id.toString(),
                    label: product.name,
                }));

                setOptionsProduct(newOptionsProduct);
            }
        }
    };

    const fetchUnits = async (id:string) => {
        if (auth && auth.api_token)
        {
            const response = await getUnitsByIdProduct(id, auth);
            
            if (response && response.data && Array.isArray(response.data))
            {
                const newOptionsUnit = response.data.map((unit: { id: any; name: any; }) => ({
                    value: unit.id.toString(),
                    label: unit.name,
                }));
        
                setOptionsUnit(newOptionsUnit);
            }
        }
    };

    useEffect(() => {
        fetchProducts();

        returnForm();
        
    }, [formData]);
    
    function returnForm()
    {
        if(formData.id !== '0')
        {
            if (typeof onFormSubmit === 'function')
            {
                onFormSubmit(formData);
            }
        }
    }
    
   
    
    // recebe o valor do cliente
    const onSelectTagify = (e:any) => {
        setNameClient(e.target.innerText);
        handleContractChange(e)
    }

    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
                        
        let name_produc = formData.name_product.toString();
        let newName = `${nameClient} - ${formData.name_unit}`.toString();
  
        setFormData(prevState => ({
            ...prevState,
            ['name']:newName as string
        }));
        
        try {

            await schemaContract.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja salvar este contrato?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {
                
                if (result.isConfirmed)
                {    
                    saveForm();                    
                }
            });
            

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }        
    };

    const saveForm =  async() => {

        if( auth && auth.api_token)
        {
            const response = await insertContractAPI(formData, auth);
            
            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Contrato salvo com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

                if(response.data.data !== "0" && response.data.data !== null && response.data.data !== "" )
                {
                    let lastId = response.data.data.toString();  

                    setFormData(prevState => ({
                        ...prevState,                
                        ['id']: lastId
                    }));
                    
                    returnForm();
                }

            }else{
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }
   
    return (
            <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal? "": "show"}`} id="modalEditParcel" tabIndex={-1} aria-modal="true" role="dialog" style={{"display": !closeModal? "none": 'block'}}>
                <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                    <div className="modal-content rounded-4">
                        <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                            <div id="" className="position-relative z-index-1"></div>

                            <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                                <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg"/>
                            </div>
                        </div>

                        <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                            <div className="container-fluid">
                                <div className="d-block">
                                    <div className="tab-content">
                                        <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                            <form onSubmit={handleSubmit}>
                                                {/* Contrato */}
                                                <div className="separator d-flex flex-center">
                                                    <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Contrato</span>
                                                </div>
                                                <div className="pe-12 me-n12 mt-15" >
                                                    <div className="row gy-5">
                                                        {/* Cliente */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Cliente</label>
                                                            <div className=''>
                                                                <TagifyCustom
                                                                    onSelectTagify={onSelectTagify}
                                                                    selectedId=""
                                                                    selectedName=""
                                                                />
                                                                {formErrors.id_client && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_client}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* produto */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Produto</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="id_product"
                                                                    className="w-auto"
                                                                    placeholder="Selecione um Produto"
                                                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                                                    options={optionsProduct}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                                                />
                                                                {formErrors.id_product && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_product}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* Unidade */}
                                                        <div className="col-lg-4  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Unidade</label>
                                                            <div className=''>
                                                                <Select
                                                                    name="id_unit"
                                                                    className="w-auto"
                                                                    placeholder="Selecione uma Unidade"
                                                                    value={formData.id_unit !== '0' && formData.id_unit !== '' ? { value: formData.id_unit, label: optionsUnit.find(option => option.value === formData.id_unit)?.label } : null}
                                                                    options={optionsUnit}
                                                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_unit")}
                                                                />
                                                                {formErrors.id_unit && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.id_unit}</div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* informações adicioanis */}
                                                        <div className="col-lg-12  fv-plugins-icon-container">
                                                            <label className='form-label fw-bold'>Informações adicionais</label>
                                                            <div className=''>
                                                                <input
                                                                    name="description"
                                                                    className="form-control"
                                                                    value={formData.description}
                                                                    onChange={handleContractChange}
                                                            
                                                                />
                                                                {formErrors.description && (
                                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.description}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="modal-footer border-0 mt-5">
                                                    <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                    <button type="submit" className="btn btn-success me-4">
                                                    {/* <button type="submit" className="btn btn-success me-4" onClick={handlerSaveForm}> */}
                                                        Salvar
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}