import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reportDashboard } from './api/dashboard/reportDashboard';
import { listProduct } from './api/product/listProduct';
import { billingPosition } from './api/payment/billingPosition';
import filterReducer from './filter/filterSlice';
import authReducer from './api/auth/auth';

const rootReducer = combineReducers({
    filter: filterReducer,
    // auth: authReducer,
    [reportDashboard.reducerPath]: reportDashboard.reducer,
    [listProduct.reducerPath]: listProduct.reducer,
    [billingPosition.reducerPath]: billingPosition.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(reportDashboard.middleware)
    .concat(listProduct.middleware)
    .concat(billingPosition.middleware),
});


setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
  
export default store;