import React, { useMemo, useState, useEffect, InputHTMLAttributes, useRef } from "react";
import { useRowState, UseRowStateLocalState, Hooks, useTable, usePagination,  TableToggleAllRowsSelectedProps, useRowSelect, Column, Row, IdType, TableExpandedToggleProps, TableToggleRowsSelectedProps } from "react-table";
import axios, { AxiosRequestConfig } from "axios";
import { TableProps } from "../../../../app/interface/I_Table";
import { KTSVG } from "../../../helpers"; 
import { Link } from "react-router-dom";
import { interfaceMap, functionMap } from "../../../helpers/functions/tables/General";
import { useAuth } from "../../../../app/modules/auth";
import Swal from "sweetalert2";
import Select from "react-select";
import { ItemTypeParcel } from "../../../../app/types/T_Parcel";

import { useSelector } from 'react-redux';
import { useFetchListProductQuery } from "../../../../app/redux/api/product/listProduct";
import { RootStateFilter } from "../../../../app/interface/I_Filter";

type Partial<T> = {
    [P in keyof T]?: T[P];
};

interface IRow {
    [key: string]: any;
  }

interface IPropops {
    gotoPage: (go:number) => void;
    previousPage: () => void;
    nextPage: () => void;
    page:any;
    totalCount:number;
    pageCount:number;
    disabledPrevious:string;
    disabledNext:string;
}

const TableAdvanced = (
        { 
            options: { 
                id, 
                header, 
                iInterface,  
                endPoint, 
                methodEndPoint, 
                title,
                classHeadTd,
                isHidePaginate, // se será exibido ou não a paginação
                funDeleteInMass, // funcao a ser chamada em caso de delete em massa
                funChangeInMass, // funcao a ser chamada em caso de alteração em massa
                textDelete, // texto para quanod for deletar exibir corretamente
                // updateTableData
            },
            tableData,
            optionsActionInMassa,
            handleInsert
    }: TableProps): JSX.Element => {

        
    const [loading, setLoading] = useState(false);
    
    const interfaceDinamic = interfaceMap[iInterface];
    type typeInterfaceDinamic = typeof interfaceDinamic;
    
    const [data, setData] = useState<typeInterfaceDinamic[]>([]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [changeInMass, setChangeInMass] = useState<{ value: string, field: string, label:string }[]>([{ value: "", field:'', label: "Nenhum" }]);

    const [totalCount, setTotalCount] = useState(0);
    const [pageSizeTable, setPageSizeTable] = useState(500);
    const [pageIndex, setPageIndex] = useState(0);

    const [propsPaginate, setProposPaginate] = useState<IPropops>();    
    
    const {auth, logout, setCurrentUser} = useAuth();

    
	const filterForm = useSelector((state:any) => state.filter);

    /**
     * Inicio cria um componente Checkbox e  resolve problema com inderteminate
     */
  
    const IndeterminateCheckbox = React.forwardRef<HTMLInputElement,{ indeterminate?: boolean } & InputHTMLAttributes<HTMLInputElement>>
        (({ indeterminate, checked, onChange, ...rest }, ref) => {
            const defaultRef = React.useRef<HTMLInputElement>(null);
            const resolvedRef = (ref || defaultRef) as React.MutableRefObject<HTMLInputElement>;

            React.useEffect(() => {
                if (resolvedRef && resolvedRef.current) {
                    resolvedRef.current.indeterminate = indeterminate ?? false;
                    resolvedRef.current.checked = checked ?? false;
                }
            }, [resolvedRef, indeterminate, checked]);

            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                  onChange(event);
                }
            };

            return (
                <>
                    <input type="checkbox" ref={resolvedRef} {...rest} onChange={handleChange}/>
                </>
            );
        }
    );

    /**
     * Fim checkBox
     */

    const API_URL = process.env.REACT_APP_API_URL
  
    const columns = React.useMemo(
        () =>

        header.map((header) => ({
            Header: header.Header,
            accessor: header.accessor as keyof typeInterfaceDinamic,
          })),
        [header]
    );

    const handlerSelectedRow = (row: Row<typeInterfaceDinamic>) => {
        const rowId: string = row.original.id.toString();
        
        setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.includes(rowId);

            if (isSelected)
            {
                // Se a linha já estiver selecionada, remova-a do array de seleção
                return prevSelectedRows.filter((selectedId) => selectedId !== rowId);
            } else {
                // Se a linha não estiver selecionada, adicione-a ao array de seleção
                return [...prevSelectedRows, rowId];
            }
        });

    }
    
    useEffect(() => {
        handlerConfirmeChangeInMass();
    }, [changeInMass]);

    /* funcao que pede confirmação antes de executar a mudança em massa*/
    const handlerConfirmeChangeInMass = () => {
        
        if( !!changeInMass[0].value )
        {
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja realizas mudança em massa nas linhas selecionadas?",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sim, continuar!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed)
                {
                    handlerMass();
                    setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                }else{
                    
                    setChangeInMass([{value: "", field:'', label: "Nenhum" }]);
                }
            });
        }
        
    }

    /* realiza mudança em massa*/
    const handlerMass = async () => {
        
        if (auth && auth.api_token)
        {
            const functionNameChange = funChangeInMass;
            if (functionNameChange && functionNameChange in functionMap)
            {
                const funChange = functionMap[funChangeInMass as keyof typeof functionMap];
                if (funChange)
                {
                    updateSelectedRows();
                    const response = await funChange(selectedRows, changeInMass, auth);
                    
                    if(response.response.success)
                    {
                        Swal.fire({
                            icon: "success",
                            title: "Parabéns!",
                            text: `${selectedRows.length || ''} Linhas alteradas com sucesso.`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        }); 

                        setSelectedRows([]);
                    }else{
                        Swal.fire({
                            icon: "info",
                            title: "Atenção",
                            html: `Não foi possível alterar as  linhas selecionadas, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        });
                    }
                }
            }
        }

    }

    /** aplica atualização nas linhas da tabela apos edição em massa */
    const updateSelectedRows = () => {
        setSelectedRows((prevSelectedRows: string[]) => {
            return prevSelectedRows.map((rowId) => {
                const row = data.find((item) => item.id.toString() === rowId);

                if (row)
                {
                    const updatedValues: { [key: string]: any } = {};
                    changeInMass.forEach((field) => {

                        // field.field -> contem nome da propriedade equivalente a propriedade do objeto da tabela
                        // field.value -> contem o novo valor que a propriedade irá receber
                        updatedValues[field.field] = field.value;
                    });

                    const updatedRow = { ...row, ...updatedValues };

                    setData((prevData) => {
                        const updatedData = prevData.map((item) => {
                            if (item.id === row.id)
                            {
                                return updatedRow;
                            }
                            return item;
                        });
                        return updatedData;
                    });

                    // return updatedRow;
                }
                return rowId;
            });
        });
    };

    /* funcao que pede confirmação antes de deletar em massa*/
    const handlerConfirmeDelete = () => {
        
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: "Deseja deletar as linhas selecionadas? Esta ação não poderá ser desfeita.",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed)
            {
                handlerDelete();
            }
        });
    }

    /* deleta as linhas selecionada em massa*/
    const handlerDelete = async () => {
        
        if (auth && auth.api_token)
        {
            const functionName = funDeleteInMass;
            if (functionName && functionName in functionMap)
            {
                const func = functionMap[funDeleteInMass as keyof typeof functionMap];
                if (func)
                {
                    const response = await func(selectedRows, auth);
                    
                    if(response.data.success)
                    {
                        Swal.fire({
                            icon: "success",
                            title: "Parabéns",
                            text: `${textDelete || 'Linhas'} deletadas com sucesso`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        }); 
                        
                        setSelectedRows([]);
                        fetchData();
                    }else{
                        Swal.fire({
                            icon: "info",
                            title: "Atenção",
                            html: `Não foi possível deletar as  linhas selecionadas, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                            showCancelButton: false,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'Entendi',
                        });
                    }
                }
            }

        }else{
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Necessário fazer novo login",
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Entendi',
            });
        }
    }

    const fetchData = async () => {
        setLoading(true);

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }
               
    console.log('filter form ', filterForm, headers)
        let config: AxiosRequestConfig = {
            method: methodEndPoint === 'post' ? 'post' : 'get',
            url: `${API_URL}/module/${endPoint}`,
            headers: headers,
            data: {
                page: pageIndex,
                pageSize: pageSizeTable,
                id: id,
                formFilter: methodEndPoint === 'post' ? filterForm : undefined,
            }
        };

        const result =  await  axios(config)

        setData(result.data.records);
        setTotalCount(result.data.totalRecords);
        setLoading(false);
        
        setProposPaginate(
            {
                gotoPage,
                previousPage,
                nextPage,
                page,
                totalCount,
                pageCount,
                disabledPrevious, 
                disabledNext
            }
        );
    };      

    useEffect(() => {
        fetchData();

    }, [pageIndex, pageSizeTable, endPoint, filterForm]);

    useEffect(() => {
        if (
            tableData && tableData.length > 0 &&
            tableData[0].hasOwnProperty('id') && tableData[0].id !== '0' && tableData[0].id !== 0 && tableData[0].id !== ''
        )
        {
            console.log('tem id  ', tableData)
            updateItem()
        }else{
            if( tableData && tableData.length > 0 )
            {
                console.log('nao tem id mas tem objeto  ', tableData)
                setTotalCount(data.length + 1)
                handleInsertInTable(tableData[0][tableData.length - 1]);
            }
        }

    }, [tableData, totalCount, filterForm]);

    // Função para atualizar os valores de um item específico com base no ID
    const updateItem = () => {
        setData(prevData => {
            const newData = prevData.map(dataItem => {
                const tableItem = tableData.find((item: { id: string | number; }) => item.id === dataItem.id);
            
                if (tableItem) {
                    // Atualize as propriedades dinamicamente
                    console.log('tableItem  ', tableItem, dataItem)
                    const updatedItem = Object.assign({}, dataItem, tableItem);
                    console.log('tableItem  ', tableItem, dataItem, updatedItem)
                    return updatedItem;
                }
            
                return dataItem;
            });

            // Adicione o item ao estado se não existir
            tableData.forEach((tableItem: { id: any; }) => {
                const exists = newData.some(dataItem => dataItem.id === tableItem.id);
                if (!exists) {
                    newData.push(tableItem);
                }
            });
          
            return newData;
        });
    };


    // Função para atualizar os valores de um item específico com base no ID
    const handleInsertInTable = (newItem: ItemTypeParcel) => {
        setData(prevData => [...prevData, newItem]);
    };
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        } = useTable<typeof interfaceDinamic>(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: pageSizeTable }
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps, rows } ) => {
                    console.log('getToggleAllRowsSelectedProps ', rows)
                    const handleToggleAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
                        const checked = event.target.checked;
                        const selectedRowIds = checked ? rows.map(row => row.id) : [];
                        setSelectedRows(selectedRowIds);
                        rows.forEach(row => row.toggleRowSelected(checked));
                        
                      };
                    return (
                        <div id="checkbox" className="form-check form-check-sm form-check-custom form-check-solid">
                            
                            {/* seleciona todos */}
                            <IndeterminateCheckbox 
                                {...getToggleAllRowsSelectedProps()} 
                                className="form-check-input widget-9-check"
                                onChange={handleToggleAllRows}
                                
                            />
                        </div>
                    )
              },
                Cell: ({ row }: { row: Row<typeInterfaceDinamic> }, index:number) => (
                    
                    <div key={index} className="form-check form-check-sm form-check-custom form-check-solid">
                        {/* seleciona individual */}
                        <IndeterminateCheckbox 
                            {...row.getToggleRowSelectedProps()} 
                            className="form-check-input widget-9-check"
                            onChange={() => handlerSelectedRow(row)}
                        />
                    </div>
                ),
              },
              ...columns,
            ]);
        }

    );
    const disabledPrevious = canPreviousPage == true ? 'disabled' : '';
    const disabledNext = canNextPage == true ? 'disabled' : '';


  return (
            <div className="flex-lg-row-fluid ">
                <div className="card">           
                    <div className="card-header align-items-center border-0 py-5 gap-5">
                        <div className="card-title">
                            <h2 className="fw-bold">{title}</h2>
                        </div>

                        <div className="d-flex align-items-center">
                            {
                                handleInsert && typeof handleInsert === 'function' && (
                                    <div className="fw-bold row me-7 mt-9 w-110px">
                                        <button
                                            className="btn btn-sm btn-light"
                                            onClick={() => handleInsert()}
                                        >
                                            Inserir novo
                                        </button>
                                    </div>
                                )
                            }

                            {selectedRows.length > 0 &&(
                                <>
                                    <div className="fw-bold fs-8 me-n12 mt-4 row w-150px">
                                        <span className="ms-n3" >{selectedRows.length} Selecionado(s)</span>
                                        <button type="button" className="btn btn-sm btn-danger me-4 w-100px" onClick={() => handlerConfirmeDelete()}>
                                            Deletar
                                        </button>
                                    </div>

                                    {optionsActionInMassa && optionsActionInMassa.length > 0 &&(
                                        <div className="fw-bold row me-1 w-200px">
                                            <label className='form-label fw-bold'>Alterar em massa para: {selectedRows.length}</label>
                                            <div className='d-flex'>
                                                <Select
                                                    className="w-450px"
                                                    placeholder=""
                                                    value={changeInMass  }
                                                    onChange={(selectedValue) => {
                                                        
                                                            const newValue = selectedValue ? { value: selectedValue.value, field: selectedValue.field, label: selectedValue.label } : { value: '', field: '', label: 'Nenhum' };
                                                            setChangeInMass([newValue]);
                                                        
                                                            handlerConfirmeChangeInMass();
                                                        }
                                                    }
                                                    options={optionsActionInMassa}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </>
                                    
                            )}

                            

                            <div className="d-flex align-items-center pagination">
                                    
                                    <span className="fw-semibold text-muted me-2 mt-2">{page.length} de {totalCount}</span>
                                    { !isHidePaginate && (
                                        <>
                                            <li className={`${disabledPrevious} page-item previous mt-2`}  >
                                                <a type="button" onClick={() => gotoPage(0)} className="page-link">
                                                    <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-muted svg-icon-1hx" />
                                                </a>
                                                {' '}
                                            </li>
                                            <li className={`${disabledPrevious} page-item previous mt-2`}>
                                                <a type="button" onClick={() => previousPage()} className="page-link" >
                                                    <i className="previous"></i>
                                                </a>
                                                {' '}
                                            </li>
                                            <li className={`${disabledNext} page-item next mt-2`}>
                                                <a type="button" onClick={() => nextPage()} className="page-link">
                                                    <i className="next"></i>
                                                </a>
                                                {' '}
                                            </li>
                                            <li className={`${disabledNext} page-item next mt-2`}>
                                                <a type="button" onClick={() => gotoPage(pageCount - 1)} className="page-link">
                                                    <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-muted svg-icon-1hx" />
                                                    
                                                </a>
                                                {' '}
                                            </li>
                                        </>
                                    )}
                                
                                {/*  */}
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="flex-wrap gap-2 justify-content-between mb-8">                                        
                        <div className="table-responsive table-loading">
                            <table {...getTableProps()} id="tableAdvanced" className="table table-hover table-rounded table-striped border gy-4 gs-4">
                                <thead className="">
                                    {
                                        headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index} className="fw-semibold table-striped fs-6 text-gray-800 border-bottom border-gray-200">
                                                {
                                                    headerGroup.headers.map((column, position) => (
                                                        <th {...column.getHeaderProps()} key={`header_${position}`} className={`sorting ${classHeadTd}`}>{column.render("Header")}</th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()} className="min-w-100px sorting">
                                {loading ? (
                                    <tr id="table_loading">
                                        <td>
                                            <div className="table-loading-message">
                                            Carregando...
                                        </div>
                                        </td>
                                    </tr>
                                ) : rows.length > 0 ? (
                                    page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={`${row.original.id}_${i}`} row-id={row.original.id} className="text-start text-muted gs-0">
                                                {
                                                    row.cells.map((cell) => {
                                                        const _isArray = Array.isArray(cell.value);                                               
                                                        return _isArray 
                                                                ? (
                                                                    <div className="d-flex align-items-center" id={`child_${row.original.id}_${i}`}>
                                                                        <div className="d-flex justify-content-start flex-column">
                                                                            <label className="text-gray-400 fw-semibold d-block fs-7">{cell.value[0] || '...'}</label>
                                                                            <div className="fw-normal text-gray-600">
                                                                                {
                                                                                    // ('idLink' in row.original ) ? 
                                                                                    //     <Link  to={`/${row.original.idLink}`}>
                                                                                    //         {cell.value[1] || '...'}
                                                                                    //     </Link>
                                                                                    //     : 
                                                                                    //     <Link  to={`#`}>
                                                                                    //         {cell.value[1] || '...'}
                                                                                    //     </Link>

                                                                                        <Link  to={`${row.original.link}`}>
                                                                                            {cell.value[1] || '...'}
                                                                                        </Link>
                                                                                }
                                                                            
                                                                                {/* <a href={`/contratos/contract/${row.original.idUnidade}`} className=""></a> */}
                                                                            </div>
                                                                        </div> 
                                                                    </div> 
                                                                ):(
                                                                    <td  {...cell.getCellProps()}>
                                                                            {cell.render("Cell") || '...'}
                                                                    </td>
                                                                    );
                                                    })
                                                }
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr className=" form-row text-start text-muted gs-0" id="table_no_data">
                                        <td colSpan={columns.length} className="text-center">Nenhum dado disponível</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                </div>
            </div>
    );
};


export default TableAdvanced;
