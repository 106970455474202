import { KTSVG } from "../../helpers"
import { ChartDonutOneLine } from "../../helpers/components/chart/ChartDonutOneLine"
import { ProgressOneLine } from "../../helpers/components/chart/ProgressOneline"

import { useSelector } from 'react-redux';
import { useFetchBillingPositionQuery } from "../../../app/redux/api/payment/billingPosition"; 
import { RootStateFilter } from "../../../app/interface/I_Filter";


export const IncomeStatement = () => {
  
    const formFilter = useSelector((state:RootStateFilter) => state.filter);

    // data passa a ser billingPosition
    const {data:billingPosition, error, isLoading} = useFetchBillingPositionQuery(formFilter);
    console.log('payment billingPosition ', billingPosition, formFilter)
    console.log('payment total ', billingPosition?.outstanding?.total)
    
    const colorProgressOneLine =  'primary'

    // liquidado
    const progressPaid =  {
        className: '', 
        width: billingPosition?.total?.paid || '0%', 
        color: 'success', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.total?.paid || '0%'
    }
    
    // vencido
    const progressOverdue =  {
        className: '', 
        width: billingPosition?.total?.overdue || '0%',
        color: 'danger', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.total?.overdue || '0%',
    }
    
    // a receber
    const progressOutstanding =  {
        className: '', 
        width: billingPosition?.total?.outstanding || '0%',
        color: 'warning', 
        title: '', 
        description: '', 
        progressBar: billingPosition?.total?.outstanding || '0%',
    }

    return (
            <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0">
                    {/* <div className="d-flex flex-wrap flex-sm-nowrap mb-6"> */}
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bold me-3">Posição de cobrança</a>
                                        <span className="badge badge-light-success me-auto">Em Progresso</span>
                                    </div>

                                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
                                        # Acompanhe o progresso de todas as cobranças
                                        
                                        <div className="col-12 mt-3">
                                            
                                            <h4>Total </h4>
                                            <span>{billingPosition?.total?.value || 'R$ 0,00'}</span>
                                            {/*  Basta mandar a porcentagem */}
                                            <ProgressOneLine 
                                                height={"h-15px"} 
                                                color={'primary'}
                                                progressPaid={progressPaid}
                                                progressOverdue={progressOverdue}
                                                progressOutstanding={progressOutstanding}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="d-flex col-12">

                                    <div className="col-md-4 col-lg-4 col-sm-12 d-flex align-items-center bg-light-success rounded p-5 mb-7">
                                        

                                        <div className="flex-grow-1 me-2">
                                            {/* <KTSVG path={"/media/icons/duotune/general/gen048.svg"} className="svg-icon-2hx" /> */}
                                            <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6"> Liquidados</a>
                                            <div className="mb-1 pe-3 flex-grow-1">
                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Valor: {billingPosition?.paid?.value || 'R$ 0,00'}</a>
                                                
                                            </div>
                                        </div>
                                        
                                        {/* Liquidados */}
                                        <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'success'} idElement={"chart_liquidados"} quantity={parseInt(billingPosition?.paid?.total) || 0} valuePercent={parseInt(billingPosition?.paid?.progress) || 0}/>   
                                        
                                        
                                    </div>

                                    <div className="col-md-4 col-lg-4 col-sm-12 ms-1 d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                                        
                                        <div className="flex-grow-1 me-2">
                                            <a href="#" className="fw-bold text-gray-800 text-hover-danger fs-6">Vencidos</a>
                                            <div className="mb-1 pe-3 flex-grow-1">
                                                <a href="#" className="fs-5 text-gray-800 text-hover-danger fw-bold">Valor: {billingPosition?.overdue?.value || 'R$ 0,00'}</a>
                                                
                                            </div>
                                        </div>
                                        
                                        <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'danger'} idElement={"chart_vencidos"} quantity={parseInt(billingPosition?.overdue?.total) || 0} valuePercent={parseInt(billingPosition?.overdue?.progress) || 0}/>
                                    </div>
                                    

                                    <div className="col-md-4 col-lg-4 col-sm-12 ms-1 d-flex align-items-center bg-light-warning rounded p-5 mb-7">
                                        <i className="ki-duotone ki-abstract-26 text-success fs-1 me-5"><span className="path1"></span><span className="path2"></span></i>

                                        <div className="flex-grow-1 me-2">
                                            <a href="#" className="fw-bold text-gray-800 text-hover-warning fs-6">A Receber</a>
                                            <div className="mb-1 pe-3 flex-grow-1">
                                                <a href="#" className="fs-5 text-gray-800 text-hover-warning fw-bold">Valor: {billingPosition?.outstanding?.value || 'R$ 0,00'}</a>
                                                {/* <div className="text-gray-400 fw-semibold fs-7">Quantidade: 13</div> */}
                                            </div>
                                        </div>
                                        
                                        <ChartDonutOneLine chartSize={35} chartLine={9} chartRotate={145} colorHex={'#E4E6EF'} classChart={'warning'} idElement={"chart_aReceber"} quantity={parseInt(billingPosition?.outstanding?.total) || 0} valuePercent={parseInt(billingPosition?.overdue?.progress) || 0}/>  
                                    </div>                                    

                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>

    )
}