import { IParticipant } from "../../../../app/interface/I_Client";
import { ICarne } from "../../../../app/interface/I_Contract";
import { IPayments } from "../../../../app/interface/I_Payments";
import { IContract } from "../../../../app/interface/I_Contract";
import { IAssociatedBillingInstallment } from "../../../../app/interface/I_Payments";
import { IInterfaceMap } from "../../../../app/interface/I_Table";
import { changeParcelInMassaApi, deleteParcelAPI, editParcelAPI } from "../../../../app/modules/parcel/_requestParcel";
import { FormValues } from "../../../../app/interface/I_Parcel"; 
import { ChangeInMass } from "../../../../app/types/T_General"; 
import { deleteContractAPI } from "../../../../app/modules/contract/_request";
import { deleteUnitsAPI } from "../../../../app/modules/unit/requestUnits";
import { deleteProductsAPI } from "../../../../app/modules/product/requestProduct";

export const interfaceMap: IInterfaceMap = {
    participante: {
        id: 1,
        initialName: "",
        link: "#",
        name: "",
        email: "",
        confirmation: "",
    } as IParticipant,
    
    carne:{
        idUnidade:0,
        idCliente:0,
        link: "#",
        paymentType:"",
        creationDate:"",
        firstExpiration:"",
        lastExpiration:"",
        email:"",
    } as ICarne,

    listPayment:{
        idContract: 0,
        idClient: 0,
        link: "#",
        contract:'',
        numberInstallment:'',
        nameClient:'',
        expiration:'',
        valueInstallment: '',
        descriptionValueInstallment: '',
        datePayment:'',
        valuePayment:'',
        situationInstallment:'',
        email:''
    } as IPayments,

    associatedBilling: {
        link: "#",
        due_date:'',
        value:'',
        createdVia:'',
        type_parcel:'',
        number_parcel: ''
    } as IAssociatedBillingInstallment,
    
    contract: {
        // id:'',
        link: "#",
        name:'',
        value:'0.0',
        status:'',
        next_payment:'',
        contract_expiration: ''
    } as IContract,    
    
    parcels:{
        link:"#",
        id: 0,
        form_payment: '',
        value: 'R$ 0,00',
        due_date: '',
        status: 'Pedente'
    } as FormValues,

};

type DynamicFunction = (...args: any[]) => any;

// Um objeto com as funções disponíveis
// export const functionMap = {
//     deleteParcel: async function deleteParcel(ids:string[], auth:any): Promise<any>
//     {
//         const response = await deleteParcelAPI(ids, auth );
//         return response;
//     }
//     // ,changeParcelInMassa: async function changeParcelInMassa(data: ChangeInMass[], auth:any): Promise<any>
//     // {
//     //     const response = await changeParcelInMassaApi(data, auth );
//     //     return response;
//     // }

// };

interface Callable {
    [key: string]: (...args: any[]) => any;
  }
  
  export const functionMap: Callable = {
    deleteParcelInMassa: async function deleteParcel(ids:string[], auth:any): Promise<any>
    {
        const response = await deleteParcelAPI(ids, auth );
        return response;
    },
    changeParcelInMassa: async function changeParcelInMassa(ids:string[], data: ChangeInMass[], auth: any): Promise<any>
    {
        const response = await changeParcelInMassaApi(ids, data, auth );
        return response;
    },
    deleteContractInMassa: async function deleteContract(ids:string[], auth:any): Promise<any>
    {
        const response = await deleteContractAPI(ids, auth );
        return response;
    },
    deleteUnitInMassa: async function deleteUnit(ids:string[], auth:any): Promise<any>
    {
        const response = await deleteUnitsAPI(ids, auth );
        return response;
    },
    deleteProductInMassa: async function deleteProducts(ids:string[], auth:any): Promise<any>
    {
        const response = await deleteProductsAPI(ids, auth );
        return response;
    }
  };
  
  


// export const handleEdit = (row: any) => {
//     // Lógica para editar a parcela com base nas informações do objeto "row"
//     console.log('Editar parcela:', row);
//     // Resto da lógica de edição aqui...
//   };


