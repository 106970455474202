import React, { useMemo, useState, useEffect, InputHTMLAttributes } from "react";
import { useTable, usePagination,  TableToggleAllRowsSelectedProps, useRowSelect, Column, Row, IdType } from "react-table";
import axios, {AxiosRequestConfig } from "axios"; 
import { Link } from "react-router-dom";
import clsx from "clsx";
import { TableProps } from "../../../../app/interface/I_Table";  
import { interfaceMap } from "../../../helpers/functions/tables/General";
import { KTSVG } from "../../../helpers";

interface IPropops {
    gotoPage: (go:number) => void;
    previousPage: () => void;
    nextPage: () => void;
    page:any;
    totalCount:number;
    pageCount:number;
    disabledPrevious:string;
    disabledNext:string;
}


const TableSimple = ({ options: { header, iInterface, title, endPoint, methodEndPoint, classPadding, classTable, classHeadTr, classHeadTd, classTbodyTdDiv, isHidePaginate } }: TableProps): JSX.Element => {
    const [loading, setLoading] = useState(false);
    console.log('methodEndPoint', methodEndPoint)
    const interfaceDinamic = interfaceMap[iInterface];
    type typeInterfaceDinamic = typeof interfaceDinamic;

    const [data, setData] = useState<typeInterfaceDinamic[]>([]);
    
    // const [selectedRows, setSelectedRows] = useState<Data[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [propsPaginate, setProposPaginate] = useState<IPropops>();

    const API_URL = process.env.REACT_APP_API_URL  

    const columns = React.useMemo(
        () =>

        header.map((header) => ({
            Header: header.Header,
            accessor: header.accessor as keyof typeInterfaceDinamic,
          })),
        [header]
    );

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            
            let config: AxiosRequestConfig = {
                method: methodEndPoint === 'post' ? 'post' : 'get',
                url: `${API_URL}/module/${endPoint}?page=${pageIndex}&pageSize=${pageSize}`,
                // url: `${API_URL}/module/${endPoint}`,
            };
            
            if (methodEndPoint.toLowerCase() === "post")
            {
                config.data = data;
            }

            const result =  await  axios(config)            

            setData(result.data.records);
            setTotalCount(result.data.totalRecords);

            setLoading(false);
            
            setProposPaginate(
                {
                    gotoPage,
                    previousPage,
                    nextPage,
                    page,
                    totalCount,
                    pageCount,
                    disabledPrevious, 
                    disabledNext
                }
                
            );
        };

        fetchData();

    }, [pageIndex, pageSize, endPoint, methodEndPoint]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        } = useTable<typeof interfaceDinamic>(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        usePagination,
        

    );


    
    const disabledPrevious = canPreviousPage == true ? 'disabled' : '';
    const disabledNext = canNextPage == true ? 'disabled' : '';

  return (                                        
        <div className="flex-lg-row-fluid ">
            <div className="card">
                { !isHidePaginate && (    
                    <div className="card-header align-items-center border-0 py-5 gap-5">
                            <div className="card-title">
                                <h2 className="fw-bold">{title}</h2>
                            </div>

                        <div className="d-flex align-items-center">
                            
                            <div className="d-flex align-items-center pagination">
                                
                                <span className="fw-semibold text-muted me-2 mt-2">{page.length} de {totalCount}</span>
                                <li className={`${disabledPrevious} page-item previous mt-2`}  >
                                    <a type="button" onClick={() => gotoPage(0)} className="page-link">
                                        <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-muted svg-icon-1hx" />
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${disabledPrevious} page-item previous mt-2`}>
                                    <a type="button" onClick={() => previousPage()} className="page-link" >
                                        <i className="previous"></i>
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${disabledNext} page-item next mt-2`}>
                                    <a type="button" onClick={() => nextPage()} className="page-link">
                                        <i className="next"></i>
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${disabledNext} page-item next mt-2`}>
                                    <a type="button" onClick={() => gotoPage(pageCount - 1)} className="page-link">
                                        <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-muted svg-icon-1hx" />
                                        
                                    </a>
                                    {' '}
                                </li>
                                {/*  */}
                                
                            </div>
                            
                        </div>
                    </div>
                
                )}
                <div className="flex-wrap gap-2 justify-content-between mb-5">                                        
                    <div className={`table-responsive table-loading ${classPadding}`}>
                        <table {...getTableProps()} className={classTable}>
                            <thead className="">
                                {
                                    headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} className={classHeadTr}>
                                            {
                                                headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()} className={`sorting ${classHeadTd}`}>{column.render("Header")}</th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()} className="min-w-100px sorting">
                            {loading ? (
                                <tr>
                                    <td>
                                        <div className="table-loading-message">
                                        Carregando...
                                    </div>
                                    </td>
                                </tr>
                            ) : rows.length > 0 ? (
                                page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} className="text-start text-muted gs-0">
                                            {
                                                row.cells.map((cell) => {
                                                    const _isArray = Array.isArray(cell.value);
                                                    return <td  {...cell.getCellProps()}>
                                                                
                                                                {_isArray ? ( 
                                                                        <div className="d-flex align-items-center">                                                     
                                                                            {
                                                                                ('initialName' in row.original ) 
                                                                                    ? 
                                                                                    <div className='symbol d-flex bg-opacity-50 me-3 align-items-center symbol-50px symbol-lg-50px bg-primary w-50px h-50px symbol-fixed position-relative'>
                                                                                        <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                                                                                            <span className="align-self-center text-center fs-1">
                                                                                            {`${row.original.initialName}`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    : 
                                                                                    <></>
                                                                            }


                                                                            <div className="d-flex justify-content-start flex-column">
                                                                                <label className="text-gray-400 fw-semibold d-block fs-7">{cell.value[0] || '...'}</label>
                                                                                
                                                                                {/* <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-8">{cell.value[1] || '...'}</span> */}
                                                                                <div className="fw-normal text-gray-600">
                                                                                    {
                                                                                        // ('idParticipant' in row.original ) ? 
                                                                                        //     <Link  to={`/contract/details/${row.original.idParticipant}`}>
                                                                                        //         {cell.value[1] || '...'}
                                                                                        //     </Link>
                                                                                        //     : 
                                                                                        //     <Link  to={`#`}>
                                                                                        //         {cell.value[1] || '...'}
                                                                                        //     </Link>

                                                                                        <Link  to={`${row.original.link}`}>
                                                                                            {cell.value[1] || '...'}
                                                                                        </Link>
                                                                                    }
                                                                                
                                                                                    {/* <a href={`/contratos/contract/${row.original.idUnidade}`} className=""></a> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>     
                                                                    
                                                                    ):(
                                                                        // cell.column.id === "action" ?
                                                                        // <button onClick={() => console.log("Botão Clicado")}>
                                                                        //     Meu Botão
                                                                        // </button> :
                                                                        cell.render("Cell")
                                                                        
                                                                    )}
                                                                
                                                            </td>;
                                                })
                                            }
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className=" form-row text-start text-muted gs-0">
                                    <td colSpan={columns.length} className="text-center">Nenhum dado disponível</td>
                                </tr>
                            )}
                            </tbody>
                        </table>                            
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TableSimple;
