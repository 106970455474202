import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { ListContractWrapper } from '../pages/contrato/ListContractWrapper'
import { ContractWrapper } from '../pages/contrato/ContractWrapper'
import { LotesWrapper } from '../pages/unit/LotesWrapper'
// import { Acordos } from '../pages/receita/Acordos'
import TasksWrapper from '../pages/tasks/TasksWrapper'
import TabContract from '../../_metronic/partials/contract/TabRouteContract'
import { PaymentsWrapper } from '../pages/pagamentos/PaymentsWrapper'
import { ParcelDetailsWrapper } from '../pages/pagamentos/ParcelDetailsWrapper'
import { PrivateRoutesDocumentation } from './PrivateRoutesDocumentation'
import { ConfigurationsWrapper } from '../pages/configurations/ConfigurationsWrapper'
import { ArquivoRetorno } from '../pages/configurations/importacao/ArquivoRetorno'
import { PixWrapper } from '../pages/configurations/PixWrapper'
import { ArquivoRemessa } from '../pages/configurations/remessa/ListArquivoRemessa'
import { ProductWrapper } from '../pages/product/ProductWrapper'
import ProductEditWrapper from '../pages/product/ProductEditWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                
                <Route path='documentation/:docs/*' element={<PrivateRoutesDocumentation />} />
                {/* Pages */}
                <Route 
                    path='dashboard' 
                    element={
                        <SuspensedView>
                            <DashboardWrapper />
                        </SuspensedView>
                    } 
                />
                <Route
                    path='tasks'
                    element={
                        <SuspensedView>
                            <TasksWrapper />
                        </SuspensedView>
                    }
                />

                {/* <Route path='menu-test' element={<MenuTestPage />} /> */}


                {/* Perfil */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                    <SuspensedView>
                        <ProfilePage />
                    </SuspensedView>
                    }
                />
                
                {/* Begin  Rotas contratos */}
                <Route
                    path='contract/list'
                    element={
                    <SuspensedView>
                        <ListContractWrapper />
                    </SuspensedView>
                    }
                />
                
                <Route
                    path='contract/details/:id/*'
                    element={
                    <SuspensedView>
                        <ContractWrapper />
                    </SuspensedView>
                    }
                />

                
                {/* End  Rotas contratos */}



                <Route
                    path='units'
                    element={
                    <SuspensedView>
                        <LotesWrapper />
                    </SuspensedView>
                    }
                />

                {/* produtos */}
                <Route
                    path='product'
                    element={
                    <SuspensedView>
                        <ProductWrapper />
                    </SuspensedView>
                    }
                />
                
                <Route
                    path='product/edit/:id/*'
                    element={
                    <SuspensedView>
                        <ProductEditWrapper />
                    </SuspensedView>
                    }
                />

                {/* pagamentos */}
                <Route
                    path='payments'
                    element={
                    <SuspensedView>
                        <PaymentsWrapper />
                    </SuspensedView>
                    }
                />

                
                <Route
                    path='payments/parcel/:id/*'
                    element={
                    <SuspensedView>
                        <ParcelDetailsWrapper />
                    </SuspensedView>
                    }
                />
                {/* End payments */}

                {/* begin configuracao */}
                <Route
                    path='configurations'
                    element={
                    <SuspensedView>
                        <ConfigurationsWrapper />
                    </SuspensedView>
                    }
                />

                <Route
                    path='configurations/import'
                    element={
                    <SuspensedView>
                        <ArquivoRetorno />
                    </SuspensedView>
                    }
                />
                <Route
                    path='configurations/export'
                    element={
                    <SuspensedView>
                        <ArquivoRemessa />
                    </SuspensedView>
                    }
                />

                <Route
                    path='configurations/pix/*'
                    element={
                    <SuspensedView>
                        <PixWrapper />
                    </SuspensedView>
                    }
                />

                <Route
                    path='apps/user-management/*'
                    element={
                    <SuspensedView>
                        <UsersPage />
                    </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
