import axios from 'axios'
import { IResponse, AuthType} from '../../interface/I_General';
import { FormValues } from '../../interface/I_Parcel';
import { ChangeInMass } from '../../types/T_General'; 


const API_URL = process.env.REACT_APP_API_URL;


// inseri uma parcela
export async function insertParcelAPI(form: FormValues, idContract:number| undefined | string, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/insertParcel`,
                            { form, idContract },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}  


// editar uma parcela
export async function editParcelAPI(form: FormValues, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/editParcel`,
                            { form },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}  

// deleta uma ou mais parcelas
export async function deleteParcelAPI(ids: string[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/deleteParcel`,
                            { ids },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// deleta condiação de pagamento
export async function deleteConditionAPI(id: any, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/deleteConditionPayment`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// altera dados da parcela em massa
export async function changeParcelInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/changeParcelInMassa`,
                            { ids, data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}

