import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../layout/core"
import { DetailsContract } from "./DetailsContract"
import { Installment } from "./Installment"
import { TabHeaderContract } from "./TabHeaderContract"
import { TypeInstallment } from "./TypeInstallment"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Contrato',
      path: 'contract/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
const TabRouteContract = ({ id }: { id: number | undefined }) => (
    <Routes>
        <Route
            element={
                <>
                    <TabHeaderContract id={id} />
                    <Outlet />
                </>
            }
        >
            <Route
                path={`overview`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Visão Contrato</PageTitle>
                        <DetailsContract id={id}/>                  
                        <TypeInstallment id={id}/>
                    </>
                }
            />
            <Route
                path={`installment`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Parcelas</PageTitle>                    
                        <Installment id={id}/>
                    </>
                }
            />
        
            <Route index element={<Navigate to={`overview`} />} />
        </Route>
    </Routes>
  )
  
  export default TabRouteContract