import axios from 'axios'
import { IDadosGerais, ICarne, ITypeInstallment, IInstallment, IFormContract } from '../../interface/I_Contract'
import { IParticipant } from '../../interface/I_Client';
import { IResponse, AuthType} from '../../interface/I_General';
import { useAuth } from '../auth';
import { ChangeInMass } from '../../types/T_General';

const DATA_GENERAL_URL = `${process.env.REACT_APP_API_URL}/module/details`;
const API_URL = process.env.REACT_APP_API_URL;


// recupera detalhes do contarto
export function detailsContract(id: number)
{
    return axios.post<IDadosGerais>(`${API_URL}/module/details`,{id});
}

// recupera os carner do contrato
export function getMeatContract(id: number) 
{
    return axios.get<ICarne>(`${API_URL}/module/getMeatContract`, { params: { id } });
}

// recupera dados do participante
export async function getParticipant(id: number) 
{
    try {
        const response = await axios.get<IParticipant>(`${API_URL}/module/getParticipant`, { params: { id } });
        // Tratar a resposta aqui
        console.log("Dados do participante:", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados do participante:", error);
        throw error;
    }

}

// recupera dados do tipo de parcela
export async function getTypeInstallment(id: number | string, auth: AuthType) 
{
    try {
       
        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getTypeInstallment`,
                            { id },
                            { headers }
                        );

        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados tipo de parcela:", error);
        throw error;
    }

}

// recupera dados do tipo de parcela
export async function getInstallment(id: number) 
{
    try {
        const response = await axios.get<IInstallment>(`${API_URL}/module/getInstallment`, { params: { id } });
        // Tratar a resposta aqui
        console.log("Dados do tipo de parcela:", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar os dados tipo de parcela:", error);
        throw error;
    }

}


// inseri um contrato
export async function insertContractAPI(form: IFormContract, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            console.log('insertContractAPI ', form)
        const response = await axios.post<IResponse>(`${API_URL}/module/insertContract`,
                            { form },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}  


// editar um contrato
export async function editContractAPI(form: IFormContract, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/editContract`,
                            { form },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}  

// deleta uma ou mais contratos
export async function deleteContractAPI(ids: string[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/deleteContract`,
                            { ids },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

// altera dados da parcela em massa
export async function changeParcelInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/changeParcelInMassa`,
                            { ids, data },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}

export const getContractByName = async(name: string, auth: AuthType) => {
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/getContractByName`,
                            { name },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}

