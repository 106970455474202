interface Item {
    [key: string]: any;
}

interface Column {
    accessor: string;
}

export const ReturnsValuesBetweenObjects = (data: Item[], columns:Column[]) => {
    const formattedData: Item[] = [];

    data.forEach((item: { [x: string]: any; }) => {
        const newItem: Item = {};

        Object.keys(item).forEach((key) => {
            const column = columns.find((c: { accessor: string; }) => c.accessor === key);

            if (column)
            {
                newItem[key] = item[key];
            }
        });

        formattedData.push(newItem);
    });

    return formattedData;

}