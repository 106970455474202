import React from "react";
import printJS from "print-js";


interface PropsTable {
    idComponent: string;
} 

export const Print = ({ idComponent }: PropsTable) => {
  const printComponent = () => {
    printJS({
      printable: idComponent,
      type: "html",
      header: "",
      style: `#${idComponent}, #${idComponent} * { font-size: 12px; }`,
      /**
       O seletor "#table-to-print" refere-se diretamente à tabela, enquanto "#table-to-print *" refere-se a todos os elementos dentro da tabela. 
       Juntos, eles aplicam o estilo a todos os elementos da tabela.
       */
    });
  };

  return (
    <div>
        <button 
            className="btn btn-sm btn-flex bg-light btn-color-primary-700 btn-active-color-primary fw-bold me-2" 
            onClick={printComponent}
        >
            Imprimir
        </button>
    </div>
  );
};

