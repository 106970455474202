import SearchGlobalsDasboard, {SearchGlobalsProps} from "../_metronic/partials/dashboard/SearchGlobalsDasboard";
import { useLocation } from 'react-router-dom';
import FormFilterLisContract from "../_metronic/partials/contract/filter/FormFilterLisContract";
import FormFilterProduct from "../_metronic/partials/product/filter/FormFilterProduct";
import FormFilterUnit from "../_metronic/partials/unit/filter/FormFilterUnit";
import FormFilterPayment from "../_metronic/partials/payments/filter/FormFilterPayment";
import FormFilterLisParcel from "../_metronic/partials/contract/filter/FormFilterLisParcel";
import { FormFilterEmpty } from "../_metronic/layout/components/filter/FormFilterEmpty";

type _url_ = {
  params?: string;
  urlPrincipal?: string | undefined;
  urlPartials?: string;
  urlAbsolute?: string;
}

var URI = process.env.REACT_APP_API_URL;
const URL: _url_ = ({
  urlPrincipal: window.location.pathname,

});

export const SwitchSearchGlobals = () => {
    
    const location = useLocation();

    console.log('location ', location)
    const isDashboardRoute = location.pathname.includes('dashboard');
    const isContractRoute = location.pathname === '/contract/list' ? true : false // location.pathname.includes('contract');
    const isContractDetailsRoute = location.pathname.includes('overview')
    const isParceltRoute = location.pathname.includes('installment');
    const isProductRoute = location.pathname.includes('product');
    const isUnitRoute = location.pathname.includes('units');
    const isPaymentRoute = location.pathname.includes('payments');
    const isConfigurationsRoute = location.pathname.includes('configurations');

    return (
        <>
            {isDashboardRoute && <SearchGlobalsDasboard/>}
            {isContractRoute && <FormFilterLisContract />}
            {isContractDetailsRoute && <FormFilterEmpty />}
            {isParceltRoute && <FormFilterLisParcel />}
            {isProductRoute && <FormFilterProduct />}
            {isUnitRoute && <FormFilterUnit />}
            {isPaymentRoute && <FormFilterPayment />}
            {isConfigurationsRoute && <FormFilterEmpty />}
        </>
    );
}
