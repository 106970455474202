import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../../../layout/core"
import { ConfigurationPix } from "../../../../layout/components/configurations/qrcodepix/ConfigurationPix"
import { TabHeaderPix } from "./TabHeaderPix"

const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Configuração',
      path: 'configurations',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  
const TabRouteHeaderPixQrCode = () => (
    <Routes>
        <Route
            element={
                <>
                    <TabHeaderPix/>
                    <Outlet />
                </>
            }
        >
            <Route
                path={`settings`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >QrCode - Pix</PageTitle>
                        <ConfigurationPix/>
                    </>
                }
            />

            <Route
                path={`qrcode`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Geração em lotes</PageTitle>
                        <ConfigurationPix/>
                    </>
                }
            />
        
            <Route index element={<Navigate to={`settings`} />} />
        </Route>
    </Routes>
  )
  
  export default TabRouteHeaderPixQrCode