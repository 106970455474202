import React, { useContext, useEffect, useState } from 'react';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";


import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';  
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';

const FormFilterLisContract: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const [selectedProduto, setSelectedProduto] = useState('');
    const [nameClient, setNameClient] = useState('');

    
    console.log('produstos query ', products)
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    interface Option {
        value: string;
        label: string;
    }

    // Init get selection dates
    const [datesDateNextPyments, setDateNextPyments] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
    });

    const [dueDatePyments, setDueDatePyments] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
    });

    function handleDateRangeNextPayment(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        setDateNextPyments({ start_date: start_date, end_date: end_date });
        console.log('handleDateRangeNextPayment ',event, picker, start_date, end_date)
    }

    function handleDateRangeDueDatePayment(event: Event, picker: any) {
        const start_date = picker.startDate;
        const end_date = picker.endDate;
        setDueDatePyments({ start_date: start_date, end_date: end_date });

        console.log('handleDateRangeDuePayment ',event, picker, start_date, end_date)
    }

    const onSelectTagify = (e:any) => {
        setNameClient(e.target.innerText);
    }

    const handleSelectChange = (value: string) => {
        setSelectedProduto(value)
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        const formDataReact = new FormData();
        // console.log("Datas selecionadas: ", dates.start_date, " - ", dates.end_date);
        
        const formDataJSON = {
            period_1: {
                start_date: datesDateNextPyments.start_date.format('YYYY-MM-DD'),
                end_date: datesDateNextPyments.end_date.format('YYYY-MM-DD')
            },
            period_2: {
                start_date: dueDatePyments.start_date.format('YYYY-MM-DD'),
                end_date: dueDatePyments.end_date.format('YYYY-MM-DD')
            },
            id_product: '',
            client:nameClient
        };

        if (selectedProduto) {
            formDataJSON.id_product = selectedProduto;
        }

        const { period_1, period_2, id_product } = formDataJSON;

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ period_1, period_2, id_product }));
        
    }

    const resetFilterForm = () => {

        const { 
            period_1, 
            period_2, 
            id_product,
            client
        } = {
            period_1: {
                start_date: '',
                end_date: ''
            },
            period_2: {
                start_date: '',
                end_date: ''
            }, 
            id_product : '',
            client:''
        };

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ period_1, period_2, id_product, client }));
    }

    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={selectedProduto !== '0' && selectedProduto !== '' ? { value: selectedProduto, label: optionsProduct.find(option => option.value === selectedProduto)?.label } : null}
                                    
                                    onChange={(e) => handleSelectChange(e?.value || '')}
                                    options={optionsProduct}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Cliente</label>
                            <div className=''>
                            <TagifyCustom
                                onSelectTagify={onSelectTagify}
                                selectedId=""
                                selectedName=""
                                endPoint="client"
                            />
                            </div>
                        </div>
                    </div>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Próximo pagamento</label>
                        <DateRangePickerComponent 
                            onDateRangeSelection={handleDateRangeNextPayment}
                            className="w-450px"
                        />
                        
                    </div>

                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Fim de contrato</label>
                        <DateRangePickerComponent 
                            onDateRangeSelection={handleDateRangeDueDatePayment}                            
                            className="w-450px"
                        />
                        
                    </div>
                    
                    
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterLisContract;
