import { type } from "os";
import { FC, useEffect, useRef } from "react";
import { string } from "yup";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../../partials";

type IPropsChartDonuts = {
    chartSize: number;
    chartLine: number;
    chartRotate: number;
    colorHex:string;
    classChart:string;
    idElement: string;
    quantity: number;
    valuePercent: number;
}

const ChartDonutOneLine:FC<IPropsChartDonuts> = ({chartSize, chartLine, chartRotate, colorHex, idElement, quantity, valuePercent, classChart}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    useEffect(() => {
      refreshChart()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])
  
    const refreshChart = () => {
      if (!chartRef.current) {
        return
      }
  
      setTimeout(() => {
        initChart(chartSize, chartLine, chartRotate,colorHex, classChart, idElement, valuePercent)
      }, 10)
    }

    return (
        <>
            <div
                id={idElement}
                ref={chartRef}
                style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
                data-kt-size={chartSize}
                data-kt-line={chartLine}
            >
              
            </div>
            <div className="d-flex-column ">
        
              <div className="12"><span className="text-gray-400 fw-semibold px-2 fs-7"> {quantity}</span></div>
              <div className="12"><span className={`fw-bold text-${classChart} py-1`}>&nbsp;&nbsp;{valuePercent}%</span></div>
            </div>
        </>
    )

}

const initChart = function (
    chartSize: number,
    chartLine: number,
    chartRotate: number,
    colorHex:string,
    classChart:string,
    idElement: string,
    valuePercent:number
  ) {
    const el = document.getElementById(idElement)
    if (!el) {
      return
    }
    el.innerHTML = ''
  
    var options = {
      size: chartSize,
      lineWidth: chartLine,
      rotate: chartRotate,
      percent: 100 ,
    }
  
    const canvas = document.createElement('canvas')
    const span = document.createElement('span')
  
    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
      // @ts-ignore
      G_vmlCanvasManager.initElement(canvas)
    }
  
    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size
  
    el.appendChild(span)
    el.appendChild(canvas)
  
    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg
  
    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2
  
    const drawCircle = function (color: string, lineWidth: number, percent: number) {
    //   percent = Math.min(Math.max(0, percent || 1), 1)
      console.log('percent ', percent)
      if (!ctx) {
        return
      }
  
      ctx.beginPath()
    //   ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
      ctx.arc(0, 0, radius, 0, percent, false)
      ctx.strokeStyle = color
      ctx.lineCap = 'round' // butt, round or square
      ctx.lineWidth = lineWidth
      ctx.stroke()
    }
  
    // Init 2
    drawCircle(colorHex, options.lineWidth, options.percent)
    // drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100 / 150)
    drawCircle(getCSSVariableValue(`--kt-${classChart}`), options.lineWidth, 100 / 250)
}

export {ChartDonutOneLine}