import axios from "axios";
import { IConfigurationPix } from "../../interface/I_Configurations";

// URLs
const API_URL = process.env.REACT_APP_API_URL

// retorna a configuração do pix
export async function getConfigurationPix()  {
    
    try {
        const response = await axios.get<IConfigurationPix>(`${API_URL}/module/getConfigurationPix`, {});
        // Tratar a resposta aqui
        console.log("retorna dados de configuracao do pix :", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao buscar configuracao do pix :", error);
        throw error;
    }

}


// envia as configurações do pix
export async function postConfigurationPix(data:IConfigurationPix)  {
    
    try {
        const response = await axios.post<IConfigurationPix>(`${API_URL}/module/postConfigurationPix`, {data});
        // Tratar a resposta aqui
        console.log("envia dados de configuracao do pix :", response);
        return response;
    } catch (error) {
        // Tratar o erro aqui
        console.log("Ocorreu um erro ao enviar configuracao do pix :", error);
        throw error;
    }

}
