import { KTSVG } from "../../helpers";
import { SetStateAction, useEffect, useState } from "react";
import { editParcelAPI, insertParcelAPI } from "../../../app/modules/parcel/_requestParcel";

import getHeaderForTypeContract from "./HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table"; 
import TableAdvanced from "../../layout/components/tables/TableAdvanced";
import { FormEditParcel } from "./modal/parcel/FormEditParcel";
import Swal from "sweetalert2";
import { useAuth } from "../../../app/modules/auth";
import { FormValues } from "../../../app/interface/I_Parcel";
import { formatDateDefaultString, formatDateToBrString } from "../../helpers/functions/export/format";
// import { FormInsertParcel } from "./modal/parcel/FormInsertParcel";

interface Parcel {
    id: string;
    // outras propriedades da Parcel
  }

export const Installment = ({ id }: { id: number | undefined }) => {

    // const [editData, setEditData] = useState(null);
    const [tableData, setTableData] = useState<FormValues[]>([]);
    const [parcelData, setParcelData] = useState<{ id: string }[]>([]);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [typeSave, setTypeSave] = useState('');
    const {auth, logout, setCurrentUser} = useAuth();


    const [formValues, setFormValues] = useState<FormValues>({
        id: 0,
        form_payment: 'Boleto',
        number_parcel: '1',
        type_parcel: '',
        value: '0.0',
        due_date: '',
        status: 'Pendente',
        is_recurring: '0',
        frequency: '1M'
    });

    interface Option {
        value: string;
        label: string;
    }


    /**
     * value: valor a atualizar no banco
     * field  o campo do banco que será atualizado
     * label: apenas texto de exibição no select
     */
    const optionsActionInMassa = [
        {
            options: [
                { value: "", field:'', label: "Nenhum" },
            ],
        },
        {            
            label: "Forma pagamento",
            options: [
                { value: "Transferencia", field:'form_payment', label: "Transferência" },
                { value: "Boleto", field:'form_payment', label: "Boleto" },
                { value: "Pix", field:'form_payment', label: "Pix" }
            ],
        },
        {
            label: "Situação",
            options: [
                { value: "Pendente", field:'status', label: "Pendente" },
                { value: "Pago", field:'status', label: "Pago" }
            ],
        },
    ];

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {
        setTypeSave('edit');

        setFormValues({
            id: row.id,
            form_payment: row.form_payment,
            value: row.value,
            due_date: formatDateDefaultString(row.due_date),
            status: row.status
        });

        setIsModalEditOpen(true);
    };

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setTypeSave('insert');
        setFormValues({
            id: 0,
            form_payment: 'Boleto',
            number_parcel: '1',
            type_parcel: '',
            value: '0.00',
            due_date: '',
            status: 'Pendente',
            is_recurring: '0',
            frequency: '1M'
        });

        setIsModalInsertOpen(true);
    };


    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };
    
    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };
    
    const handleChange = (fieldName: keyof FormValues, value: string | null) => {
        setFormValues({
        ...formValues,
        [fieldName]: value,
        });
    };

    
    const confirmSaveForm = () => {

        let textAlert = 'Deseja salvar as mudanças feitas nesta parcela?';
        if(typeSave == 'insert')
        {
            textAlert = 'Deseja salvar esta nova parcela?';
        }else{
            formValues.type_parcel = '1';
        }

        if( !!formValues.due_date && !!formValues.type_parcel && !!formValues.value && formValues.value !== "R$ 0")
        {

            if(formValues.status === 'Recorrente' && formValues.is_recurring === "0")
            {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Para que a situação seja marcada como 'Recorrente' o campo recorrência deve ser 'Sim'",
                    showCancelButton: false,
                    confirmButtonText: 'Ok, entendi!'
                });
            }else if( formValues.due_date !== "Data inválida")
            {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: textAlert,
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed)
                    {
                        saveForm();
                    }
                }); 
            }else{
                
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                }); 
            }
        }else{
            
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    const saveForm = async () => {
        console.log('verifica tipo save ', typeSave)
        
        if( auth && auth.api_token)
        {
            let response;
            if(typeSave == 'edit')
            {
                response = await editParcelAPI(formValues, auth);
            }else{
                
                response = await insertParcelAPI(formValues, id, auth);
            }

            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Parcela salva com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });
                
                formValues.due_date = formatDateToBrString(formValues.due_date);

                if(typeSave === 'insert')
                {
                    if (response.data.data !== null) 
                    {
                        formValues.id = response.data.data.toString();
                    }
                }
                
                console.log('response.data ', response.data,  response.data.success)

                setTableData([formValues]);
                setIsModalEditOpen(false);
                setIsModalInsertOpen(false);
                
            }else{
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    console.log('formValues ', formValues, typeSave)

    // Propopriedade para montar header e endopoint dinamico
    const optionsInstallment: TableOptions = {
        id:id,
        header: getHeaderForTypeContract('installment', handleEdit),
        iInterface: 'installment',
        endPoint: `getInstallment`,
        methodEndPoint: 'post',
        title:'Lista de Parcelas',
        classHeadTd: 'min-w-150px',
        classTbody:'fw-semibold text-gray-800',
        classtbodyTr:'',
        classTbodyTd:'',
        isHidePaginate : true,
        funDeleteInMass: 'deleteParcelInMassa',
        funChangeInMass: 'changeParcelInMassa',
        textDelete: 'Parcelas',
    };

    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <TableAdvanced 
                options={optionsInstallment} 
                optionsActionInMassa={optionsActionInMassa}
                tableData={tableData}
                // handleInsert={handleInsert}
            />
            {isModalEditOpen && 
                <FormEditParcel 
                    closeModal={closeModalEdit} 
                    handlerSaveForm={confirmSaveForm} 
                    formValues={formValues}
                    handleChange={handleChange}
                />
            }
            
            {/* {isModalInsertOpen && 
                <FormInsertParcel 
                    closeModal={closeModalInsert} 
                    handlerSaveForm={confirmSaveForm} 
                    formValues={formValues}
                    handleChange={handleChange}
                />
            } */}

        </div>

    );
}