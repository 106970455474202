
import React, { useState } from "react";
import FormFilterDashBoard from "./FormFilterDashboard";

interface SearchGlobalsProps {
    searchQuery: string; // a string de busca que o usuário digitou
    setSearchQuery: (query: string) => void; // uma função para atualizar a string de busca
    onSearch: () => void; // uma função para executar a busca quando o usuário pressionar "Enter"
    isLoading: boolean; // um sinalizador para mostrar que a busca está em andamento
    results: SearchResult[]; // os resultados da busca
  }

  
  
  interface SearchResult {
    id: number;
    title: string;
    description: string;
    url: string;
  }

const SearchGlobalsDasboard: React.FC = () => {

    
    
    return (
            <>
                <div className="d-flex justify-content-start mb-10 mb-10">
                    <FormFilterDashBoard></FormFilterDashBoard>
                </div>
          </>
      )
};

export type { SearchGlobalsProps }; // exporta o tipo do componente

export  default SearchGlobalsDasboard; // exporta o componente padrão



  