import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ITypeInstallment } from "../../../app/interface/I_Contract";
import { IResponse } from "../../../app/interface/I_General";
import { FormValues } from "../../../app/interface/I_Parcel";
import { useAuth } from "../../../app/modules/auth";
import { getTypeInstallment } from "../../../app/modules/contract/_request";
import { deleteConditionAPI, editParcelAPI, insertParcelAPI } from "../../../app/modules/parcel/_requestParcel";
import { KTSVG } from "../../helpers";
import { formatDateDefaultString, formatDateToBrString } from "../../helpers/functions/export/format";
import { FormInsertParcel } from "./modal/parcel/FormInsertParcel";

export const TypeInstallment = ({ id }: { id: number | undefined }) => {
    
    const [contractTypeInstallment, setcontractTypeInstallment] = useState<any>([]);
    
    const [isModalConditionPaymentOpen, setIsModalConditionPaymentOpen] = useState(false);
    const [typeSave, setTypeSave] = useState('');
    const {auth, logout, setCurrentUser} = useAuth();

    const typeParcel = [
        { value: "AV", label: "Á vista" },
        { value: "CO", label: "Comissão Corretor" },
        { value: "COR", label: "Comissão Corretor (Repasse)" },
        { value: "CH", label: "Comissão Interna" },
        { value: "CHR", label: "Comissão Interna (Repasse)" },
        { value: "DS", label: "Desconto" },
        { value: "SI", label: "Entrada" },
        { value: "FG", label: "FGTS" },
        { value: "FB", label: "Financiamento Bancário" },
        { value: "IT", label: "Intermediaria"},
        { value: "PC", label: "Parcela de chaves"},
        { value: "PM", label: "Parcela Mensal" },
        { value: "SB", label: "Subsídio" }
    ];
    
    // seta valores padrões para o formulario
    const [formValues, setFormValues] = useState<FormValues>({
        id: 0,
        number_parcel: '1',
        is_recurring: '0',
        frequency: '1M',
        form_payment: 'boleto',
        type_parcel: '',
        first_due_date: '',
        value: '0.0',
        status: 'pendente',
        readjustment_index: 'nenhum',
        interest_rate: '',
        generate_billing_installments: 'sim',
        table:'payment_conditions'

    });
    
    // valores padrões para erros
    const [errorsForm, setErrorsForm] = useState<FormValues>({
        id: 0,
        number_parcel: '',
        is_recurring: '',
        frequency: '1M',
        form_payment: '',
        type_parcel: '',
        first_due_date: '',
        value: '',
        status: '',
        interest_rate: '',
        readjustment_index: 'nenhum',

    });

    /** ao clicar no botão inserir reseta os campos */
    const handleInsertConditionPayment = () => {
        setTypeSave('insert');
        setFormValues({
            id: 0,
            number_parcel: '1',
            is_recurring: '0',
            frequency: '1M',
            form_payment: 'boleto',
            type_parcel: '',
            first_due_date: '',
            value: '0.0',
            status: 'pendente',
            readjustment_index: 'nenhum',
            interest_rate: '',
            generate_billing_installments: 'sim',
            table:'payment_conditions'
        });        
        
        setIsModalConditionPaymentOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEditConditionPayment = (row: any) => {
        setTypeSave('edit');
        console.log('hnlderEdit ', row)
        setFormValues({
            id: row.id,           
            number_parcel: row.number_parcel || '1',
            is_recurring: row.is_recurring || '0',
            frequency: row.frequency || '1M',
            form_payment: row.form_payment || 'boleto',            
            type_parcel: row.type_parcel || '',
            first_due_date: formatDateDefaultString(row.first_due_date),
            value: row.value || '0.0',
            status: row.status || 'pendente',
            readjustment_index: row.readjustment_index || 'nenhum',
            interest_rate: row.interest_rate || '',
            generate_billing_installments: row.generate_billing_installments || 'nao',
            table:'payment_conditions'
        });

        setIsModalConditionPaymentOpen(true);
    };

    const handleChange = (fieldName: keyof FormValues, value: string | null) => {
        setFormValues({
        ...formValues,
        [fieldName]: value,
        });
    };
      
    /** modal inserir */
    const closeModalParcelInsert = () => {
        setIsModalConditionPaymentOpen(false);
    };

    /** pede confirmação antes de deletar */
    const confirmDeletConditionPayment = (id: any) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            html: "Deseja deletar esta condição de pagamento, <br> esta ação não poderá ser desfeita!",
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, Salvar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed)
            {
                handleDeleteConditionPayment(id);
            }
        }); 
    }

    /** deleta do servidor */
    const handleDeleteConditionPayment = async (id:any) => {
        if( auth && auth.api_token)
        {
            let response;
            
            response = await deleteConditionAPI(id, auth);
            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Condição de pagamento deletado com sucesso com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

                const novoArray = [formValues].filter((item: any) => item === id);
                const newValue = novoArray[0];
                setFormValues(newValue);

                const element = document.getElementById(`condition_payment_${id}`);
                element?.remove();
            }

        }
    }

    /** pede confirm,ação antes de salvar */
    const confirmSaveForm = () => {

        let textAlert = 'Deseja salvar as mudanças feitas nesta condição de pagamento?';
        if(typeSave == 'insert')
        {
            textAlert = 'Deseja salvar a condição de pagamento?';
        }

        if( !!formValues.first_due_date && !!formValues.value && formValues.value !== "R$ 0" && formValues.generate_billing_installments !== '% 0')
        {
            if(formValues.status === 'Recorrente' && formValues.is_recurring === "0")
            {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Para que a situação seja marcada como 'Recorrente' o campo recorrência deve ser 'Sim'",
                    showCancelButton: false,
                    confirmButtonText: 'Ok, entendi!'
                });
            }else if( formValues.first_due_date !== "Data inválida")
            {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: textAlert,
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed)
                    {
                        saveForm();
                    }
                }); 
            }else{
                
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                }); 
            }
        }else{
            
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    /** Envia para api */
    const saveForm = async () => {
        console.log('verifica tipo save ', typeSave)
        
        if( auth && auth.api_token)
        {
            let response;
            if(typeSave == 'edit')
            {
                response = await editParcelAPI(formValues, auth);
            }else{
                
                response = await insertParcelAPI(formValues, id, auth);
            }

            if(response.data.success)
            {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Parcela salva com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });
                
                formValues.first_due_date = formatDateToBrString(formValues.first_due_date);

                if(typeSave === 'insert')
                {
                    if (response.data.data !== null) 
                    {
                        formValues.id = response.data.data.toString();
                    }
                }

                updatedState()                

                // setTableData([formValues]);
                // setIsModalEditOpen(false);
                setIsModalConditionPaymentOpen(false);
                
            }else{
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    
    console.log('formValues ', formValues)
    
    useEffect(() => {
        async function fetchData() {
            try {
                if (auth && auth.api_token)
                {
                    const response = await getTypeInstallment(id || 0, auth);
                    console.log('response', response)
                    if(response.data.success)
                    {

                        setcontractTypeInstallment(response.data.data);
                    }
                }
            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseTypeInstallment:", error);
            }
        }
    
        fetchData();
    }, [id]);

    const updatedState = () => {
        setcontractTypeInstallment((prevData: any) => {
            
            console.log('prev ', prevData)
            return prevData.map((dataItem: any) => {
                const conditions = [formValues].find((item: any) => item.id === dataItem.id);
                
                if (conditions) {
                  const updatedItem = Object.assign({}, dataItem, conditions);
                  return updatedItem;
                }
          
                return dataItem;
            });
        });        
    }

    useEffect(() => {
        // updatedState()
    }, [])
    
    
    return (
        <div className="card pt-4 mb-6 mb-xl-9"> 
            <div className="card-header border-1">
                <div className="card-title">
                    <h2 className="fw-bold mb-0">Condições de pagamento</h2>
                </div>
                <div className="fw-bold mt-4">
                    <button
                        className="btn btn-sm btn-light"
                        onClick={() => handleInsertConditionPayment()}
                    >
                        Inserir condição de pagamento
                    </button>
                </div>
            </div>

            {contractTypeInstallment && Array.isArray(contractTypeInstallment) && contractTypeInstallment.length > 0 && (
                <>
                    {
                        contractTypeInstallment.map((value, index) => {
                            //    const _divisor = index !== contractTypeInstallment.data - 1 ? <><div className="separator my-2"></div></> : '';
                            const _divisor = <><div className="separator my-2"></div></>;
                            console.log('value parcel', value)

                            const ObjDate =  new Date(value.first_due_date);
                            let first_day_due_date = ObjDate.getDate();

                            let due_date = first_day_due_date < 10 ? `0${first_day_due_date}` : first_day_due_date;

                            let is_recurring = value.is_recurring == 0 || value.is_recurring == '0' ? `Recorrente` : 'Não Recorrente';

                            let type_parcel = typeParcel.find(item => item.value === value.type_parcel)?.label || '';

                            {
                                return (
                                            <div key={`type_installment_${value.id}`} id={`condition_payment_${value.id}`}  className="card-body mb-n7 pt-0">
                                    
                                                <div className="py-0" data-kt-customer-payment-method="row">
                                                    <div className="py-3 d-flex flex-stack flex-wrap">
                                                        <a className="d-flex align-items-center collapsible rotate collapsed" data-bs-toggle="collapse" href={`#type_installment_ref_${value.id}`} role="button" aria-expanded="false" aria-controls={`type_installment_ref_${value.id}`}>
                                                            <div className="me-3 rotate-90"><KTSVG path={"/media/icons/duotune/arrows/arr071.svg"}/></div>
                                                                
                                                            {/* <img src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" className="w-40px me-3" alt="" /> */}

                                                            <div className="me-3">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="text-gray-800 fw-bold">
                                                                        Tipo de cobrança
                                                                    </div>

                                                                    <div className="badge badge-light-primary ms-5">
                                                                        { value.form_payment.toUpperCase() }
                                                                    </div>
                                                                </div>
                                                                <div className="text-muted">Quantidade de parcela: {value.number_parcel < 10 ? `0${value.number_parcel}` : value.number_parcel} - Dia do Vencimento: {due_date}</div>
                                                            </div>
                                                        </a>

                                                        <div className="d-flex my-3 ms-9">
                                                            {/* <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
                                                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" aria-label="Edit" data-bs-original-title="Edit" data-kt-initialized="1">
                                                                    <KTSVG path={"/media/icons/duotune/art/art005.svg"}/>
                                                                </span>
                                                            </a> */}
                                                            <button
                                                                className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                                onClick={() => handleEditConditionPayment(value)}
                                                            >
                                                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" aria-label="Edit" data-bs-original-title="Edit" data-kt-initialized="1">
                                                                    <KTSVG path={"/media/icons/duotune/art/art005.svg"}/>
                                                                </span>
                                                            </button>                                                            

                                                            <button
                                                                className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                                onClick={() => confirmDeletConditionPayment(value.id)}
                                                            >
                                                                <KTSVG path={"/media/icons/duotune/general/gen027.svg"}/>
                                                            </button>  

                                                            {/* <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" data-kt-customer-payment-method="delete" aria-label="Delete" data-bs-original-title="Delete" data-kt-initialized="1">
                                                                <KTSVG path={"/media/icons/duotune/general/gen027.svg"}/>
                                                            </a> */}
                                                            
                                                        </div>
                                                    </div>

                                                    <div id={`type_installment_ref_${value.id}`} className="fs-6 ps-10 collapse" data-bs-parent={`#type_installment_${value.id}`}>
                                                        <div className="d-flex flex-wrap py-5">
                                                            <div className="flex-equal me-5">
                                                                <table className="table table-flush fw-semibold gy-1">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="min-w-200px w-250px">
                                                                                <label className="w-250px">Quantidade de parcelas</label>
                                                                                <div className="fw-normal text-gray-600">{value.number_parcel < 10 ? `0${value.number_parcel}` : value.number_parcel}</div>
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">Valor da parcela</label>
                                                                                {/* <div className="fw-normal text-gray-600">{value.value}</div> */}
                                                                                <div className="fw-normal text-gray-600">{parseFloat(value.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || '...'}</div>
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">Recorrência</label>
                                                                                <div className="fw-normal text-gray-600">{is_recurring}</div>
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">Tipo de parcela</label>
                                                                                <div className="fw-normal text-gray-600">{type_parcel}</div>
                                                                            </td>

                                                                        
                                                                        </tr>
                                                                        <tr>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">1º Vencimento</label>
                                                                                <div className="fw-normal text-gray-600">
                                                                                    { ObjDate.toLocaleString('pt-BR')}
                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                <label className="w-150px">Taxa de desconto</label>
                                                                                <div className="fw-normal text-gray-600">{parseFloat(value?.interest_rate).toLocaleString('pt-BR',{ minimumFractionDigits: 1}) + '%' || '...'}</div>
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">Índice de Reajustes</label>
                                                                                <div className="fw-normal text-gray-600">{value?.readjustment_index || ''}</div>
                                                                            </td>
                                                                            
                                                                            <td>
                                                                                <label className="w-150px">Parcelas Geradas?</label>
                                                                                <div className="fw-normal text-gray-600">{value?.generate_billing_installments.toUpperCase() || ''}</div>
                                                                            </td>

                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            {_divisor}
                                    </div>
                                )
                            }
                        })
                    }
                </>
            )}

            {isModalConditionPaymentOpen && 
                <FormInsertParcel 
                    closeModal={closeModalParcelInsert} 
                    handlerSaveForm={confirmSaveForm} 
                    formValues={formValues}
                    handleChange={handleChange}
                />
            }
        </div>
    );
}