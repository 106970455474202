import axios from 'axios'
import { IResponse, AuthType} from '../../interface/I_General';
import { IFormUnit } from '../../interface/I_Unit';

const API_URL = process.env.REACT_APP_API_URL;

export const getUnitsByIdProduct = async (idProduct:string, auth: AuthType)  => {

    try{
        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/unitByProduct`,
                            {idProduct},
                            { headers }
                        );

        return response;
    }catch(error){

    }
}


// editar um contrato
export async function editUnitAPI(form: IFormUnit, auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/editUnit`,
                            { form },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }

}  

// deleta uma ou mais unidades
export async function deleteUnitsAPI(ids: string[], auth: AuthType) 
{
    try {

        let headers = {};
        if (auth && auth.api_token)
        {
            headers = { Authorization: `Bearer ${auth.api_token}` };
        }            
            
        const response = await axios.post<IResponse>(`${API_URL}/module/deleteUnit`,
                            { ids },
                            { headers }
                        );

        return response;
    } catch (error) {
        throw error;
    }
}
