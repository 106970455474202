import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import "moment/locale/pt-br";
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { getUnitsByIdProduct } from '../../../../app/modules/unit/requestUnits';
import { useAuth } from '../../../../app/modules/auth';

const FormFilterUnit: React.FC = () => {

    const dispatch = useDispatch();
    
	const { data:products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);

    const {auth, logout, setCurrentUser} = useAuth();

    interface Option {
        value: string;
        label: string;
    }

    interface OptionSelected {
        id_product: string;
        id_unit: string;
    }
        
    const optionsTypePlan: Option[] = [
        { value: "H", label: "Terreno" },
        { value: "V", label: "Predio" }
    ];

    const [formData, setFormData] = useState({id_product: '0', id_unit: '0'});
    const [optionsUnit, setOptionsUnit] = useState<Option[]>([]);
    
    console.log('produstos query ', products)
    
    const fetchProducts = () => {
        
        if (products && Array.isArray(products))
        {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(()=> {
        fetchProducts();
    },[products]);

    interface Option {
        value: string;
        label: string;
    }

    
    
    console.log('formData ', formData)   
   

    const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: Option | null,fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "0";
        if(fieldName === 'id_product')
        {
            fetchUnits(value);

            setFormData(prevState => ({
                ...prevState,                
                [fieldName]: value.toString(),
            }));

        }else{
            
            console.log('tipo', fieldName, value)
            setFormData(prevState => ({
                ...prevState,
                [fieldName]: value.toString()
            }));
        }
    };

    const fetchUnits = async (id:string) => {
        if (auth && auth.api_token)
        {
            const response = await getUnitsByIdProduct(id, auth);
            
            if (response && response.data && Array.isArray(response.data))
            {
                const newOptionsUnit = response.data.map((unit: { id: any; name: any; }) => ({
                    value: unit.id.toString(),
                    label: unit.name,
                }));
        
                setOptionsUnit(newOptionsUnit);
            }
        }
    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        
        event.preventDefault();
        const { id_product, id_unit } = formData;

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ id_product, id_unit }));
        
    }

    const resetFilterForm = () => {

        const { 
            id_product,
            id_unit,
        } = {
            id_product: '',
            id_unit: '',
        };

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({ id_product, id_unit }));
    }

    return (
        
            <form id="form_filter_contract" onSubmit={handleSubmit}>
                <div className="d-flex flex-column align-items align-items-start">
                    <div className="d-flex align-items-center mb-2"></div>
                    <div className='mb-10 row'>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className='form-label fw-bold'>Unidade</label>
                            <div className='d-flex'>
                                <Select
                                    name="id_unit"
                                    className="w-auto"
                                    placeholder="Selecione uma Unidade"
                                    value={formData.id_unit !== '0' && formData.id_unit !== '' ? { value: formData.id_unit, label: optionsUnit.find(option => option.value === formData.id_unit)?.label } : null}
                                    options={optionsUnit}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption as Option | null, "id_unit")}
                                />
                            </div>
                        </div>
                    </div>                    
                    
                </div>
                <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                        <div className='d-flex justify-content-center'>
                            <button
                                type='reset'
                                className='btn btn-sm btn-light btn-active-light-primary me-2'
                                data-kt-menu-dismiss='true'
                                onClick={() => resetFilterForm()}
                            >
                                Resetar filtro
                            </button>

                            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                                Pesquisar
                            </button>
                        </div>
                    </div>
            </form> 
    );
};

export default FormFilterUnit;
