import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination, Column } from "react-table";
import { ButtonExcel } from "../../../_metronic/layout/components/buttons/ButtonExcel";
import { ButtonCsv } from "../../../_metronic/layout/components/buttons/ButtonCsv";
import { ExportTablePdf } from "../../../_metronic/layout/components/export/ExportTablePdf";
import axios from "axios";
import { KTSVG } from "../../../_metronic/helpers";
import ReactExport from "react-data-export-typescript";
import { ReturnsValuesBetweenObjects } from "../../../_metronic/helpers/functions/export/ReturnsValuesBetweenObjects";
import { ITableDefaulters } from "../../interface/I_Dashboard";
import HeadersTablesDasboard from "./table/HeadersTablesDasboard";
import { HTMLToPDFButton } from "../../../_metronic/layout/components/export/ExportHmtlPdf";
import { CopiedTable } from "../../../_metronic/layout/components/export/Copie";
import { Print } from "../../../_metronic/layout/components/export/Print";



const TableDefaulters: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ITableDefaulters[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

    const API_URL = process.env.REACT_APP_API_URL
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const columns = React.useMemo(
        () =>
            HeadersTablesDasboard('defaulters').map((header) => ({
                Header: header.Header,
                accessor: header.accessor as keyof ITableDefaulters,
            })),
        []
    );

    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result = await axios.post(
                
                `${API_URL}/module/inadimplentes?page=${pageIndex}&pageSize=${pageSize}`,
                {}, // passando um corpo vazio, caso não precise enviar nenhum dado no corpo da requisição
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                
            );
            console.log('result ', result.data)
            setData(result.data.records);
            setTotalCount(result.data.totalRecords);
            setLoading(false);
        };
        fetchData();
    }, [pageIndex, pageSize]);

    const exportHeaderPdf = columns.map((col) => ({ header: col.Header, dataKey: col.accessor }));
   
    const columnsExport = HeadersTablesDasboard('defaulters').map((header) => ({
                                Header: header.Header,
                                accessor: header.accessor as keyof ITableDefaulters,
                            }));

    const exportData =  ReturnsValuesBetweenObjects(data, columnsExport).map(item => ({
                            name: item.name,
                            email: item.email,
                            phone: item.phone,
                        }));
    
    const exportFileName = "Disposição de Cobrança"; // o nome do arquivo que será gerado

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable<ITableDefaulters>(
        {
        columns,
        data,
        initialState: { pageIndex: 0 }
        },
        usePagination
    );

    const hableDisablePrevious = canPreviousPage == true ? 'disabled' : '';
    const hableDisableNext = canNextPage == true ? 'disabled' : '';

  
  
    return (
        <>
            <div className="">
                <div className="card">
                    <div className="card-header align-items-center py-5 gap-5">                                
                        <div className="d-flex">                            
                            <ExcelFile element={<ButtonExcel />}>
                                <ExcelSheet data={data} name="Posição de cobrança">
                                    {
                                        columns.map((column) => (
                                            <ExcelColumn label={column.Header as keyof ITableDefaulters} value={column.accessor as keyof ITableDefaulters} />
                                        ))
                                    }
                                
                                </ExcelSheet>
                            </ExcelFile>  

                            <ExcelFile fileExtension={'csv'} element={<ButtonCsv />}>
                                <ExcelSheet data={data} name="Posição de cobrança">
                                    {
                                        columns.map((column) => (
                                            <ExcelColumn label={column.Header as keyof ITableDefaulters} value={column.accessor as keyof ITableDefaulters} />
                                        ))
                                    }
                                
                                </ExcelSheet>
                            </ExcelFile>    
                            {/* <HTMLToPDFButton/> */}
                            {/* <ButtonPDF/> */}
                            <ExportTablePdf
                                exportData={exportData}
                                exportFileName={exportFileName}
                                exportHeader={exportHeaderPdf}
                            />
                            <CopiedTable
                                exportHeader={columnsExport}
                                exportData={exportData}                            
                            />
                            <Print idComponent="table_defaulters"/>
                            
                        </div>
                        
                        <div className="d-flex align-items-center pagination">
                            {/*  */}
                            
                                <span className="fw-semibold text-muted me-2 mt-2">{page.length} de {totalCount}</span>
                                
                                <li className={`${hableDisablePrevious} page-item previous mt-2`}  >
                                    <a type="button" onClick={() => gotoPage(0)} className="page-link">
                                        <KTSVG path="/media/icons/duotune/arrows/arr021.svg" className="svg-icon-muted svg-icon-1hx" />
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${hableDisablePrevious} page-item previous mt-2`}>
                                    <a type="button" onClick={() => previousPage()} className="page-link" >
                                        <i className="previous"></i>
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${hableDisableNext} page-item next mt-2`}>
                                    <a type="button" onClick={() => nextPage()} className="page-link">
                                        <i className="next"></i>
                                    </a>
                                    {' '}
                                </li>
                                <li className={`${hableDisableNext} page-item next mt-2`}>
                                    <a type="button" onClick={() => gotoPage(pageCount - 1)} className="page-link">
                                        <KTSVG path="/media/icons/duotune/arrows/arr024.svg" className="svg-icon-muted svg-icon-1hx" />
                                        
                                    </a>
                                    {' '}
                                </li>
                            
                            {/*  */}
                            
                        </div>
                        
                    </div>
                    
                    <div className="card-body">
                        
                        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                                
                            <div className="table-responsive table-loading">

                                <table {...getTableProps()} id="table_defaulters" className="table table-hover table-rounded table-striped border gy-7 gs-7">
                                    <thead className="">
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} className="fw-semibold table-striped fs-6 text-gray-800 border-bottom border-gray-200">
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()} className="min-w-100px sorting">{column.render("Header")}</th>
                                            ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="min-w-100px sorting">
                                    {loading ? (
                                        <tr>
                                            <td>
                                                <div className="table-loading-message">
                                                Carregando...
                                            </div>
                                            </td>
                                        </tr>
                                    ) : rows.length > 0 ? (
                                        page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} className="text-start text-muted gs-0">
                                            {row.cells.map((cell) => {
                                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })}
                                            </tr>
                                        );
                                        })
                                    ) : (
                                        <tr className=" form-row text-start text-muted gs-0">
                                            <td colSpan={columns.length} className="text-center">Nenhum dado disponível</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableDefaulters;
