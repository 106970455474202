type Column = {
    Header: string;
    accessor: string;
};

type Row = {
    [key: string]: string;
};

interface PropsTable {
    exportHeader: Column[];
    exportData: Row[];
}  

export const CopiedTable = ({ exportHeader, exportData }: PropsTable) => {
    const copyTable = () => {

        const tableHeader = exportHeader.map((column) => column.Header).join("\t");
        const tableRows = exportData.map((row) => exportHeader.map((column) => row[column.accessor]).join("\t")).join("\n");
        
        const table =  `${tableHeader}\n${tableRows}`;         
          
        navigator.clipboard.writeText(table);
    };
  
    return (
      <div>
        
            <button 
                className="btn btn-sm btn-flex bg-light btn-color-primary-700 btn-active-color-primary fw-bold me-2" 
                onClick={copyTable}
            >
                Copiar
            </button>
        
      </div>
    );
};

  