import { IHeaderWithCell } from "../../../interface";
  

export const HeadersTablesDasboard = (type: string): IHeaderWithCell[] => {
    switch (type) {

            case 'defaulters':
            return [
                { Header: 'Nome', accessor: 'name' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Telefone', accessor: 'phone' }
            ];
        default:
            return [
                { Header: 'Nome', accessor: 'name' },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Telefone', accessor: 'phone' }
            ];
    }
}; 

export default HeadersTablesDasboard